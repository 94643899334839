import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listAllControllServices() {
  const serviceControllUrl = `${API_URL}/service-controll`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllControllServicesPageNumber(pageNumber) {
  const serviceControllUrl = `${API_URL}/service-controll?page=${pageNumber}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createServiceControll(data) {
  const serviceControllUrl = `${API_URL}/service-controll`;
  const response = await axiosInstance.post(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateServiceControll(data) {
  const serviceControllUrl = `${API_URL}/service-controll/${data.id}`;
  const response = await axiosInstance.put(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function nextStepServiceControll(serviceControllId) {
  const serviceControllUrl = `${API_URL}/service-controll/next-step/${serviceControllId}`;
  const response = await axiosInstance.put(
    serviceControllUrl,
    {},
    {
      headers: getAuthHeaders(),
    }
  );
  return response.data;
}


export async function deleteServiceControll(serviceControllId) {
  const serviceControllUrl = `${API_URL}/service-controll/${serviceControllId}`;
  const response = await axiosInstance.delete(
    serviceControllUrl,
    {
      headers: getAuthHeaders(),
    }
  );
  return response.data;
}

