import React, { useState } from "react";
import { CDBModal, CDBContainer } from "cdbreact";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen, props.companiesList]);

  function handleSubmit(event) {
    try {
      const companyString = event.target.value;
      const company = JSON.parse(companyString);
      localStorage.setItem("companyDocumentNumber", company.documentNumber);
      localStorage.setItem("companyName", company.fantasyName);
      localStorage.setItem("isAdminUser", true);

      setSelectedCompany(company);
    } catch (error) {
      localStorage.clear();
      setSelectedCompany(null);
    }
  }

  function onClickButtonSelect() {
    if (!selectedCompany) {
      alert("Selecione uma opção");
      return;
    }
    setIsOpen(false);
    history.push("/dashboard");
  }

  return (
    <CDBContainer>
      <CDBModal isOpen={isOpen} fade>
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Selecione uma Empresa</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setIsOpen(false)}
              ></button>
            </div>

            <div className="modal-body">
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={handleSubmit}
                required
              >
                <option value={null}>Selecione uma opção</option>
                {props.companiesList.map((company) => (
                  <option
                    key={company.documentNumber}
                    value={JSON.stringify(company)}
                  >
                    {company.fantasyName} | {company.documentNumber}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer">
              <button
                onClick={onClickButtonSelect}
                type="button"
                className="btn btn-dark"
              >
                Selecionar
              </button>
              <button
                onClick={() => setIsOpen(false)}
                type="button"
                className="btn btn-danger"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </CDBModal>
    </CDBContainer>
  );
};
export default Modal;
