import React, { useEffect, useState } from "react";
import AccordionFechamentoCaixa from "../AccordionFechamentoCaixa";
import { generateReportCashFlow } from "../../../../services/ReportService";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import { CDBSpinner } from "cdbreact";

const FechamentoCaixaModal = () => {
  const [report, setReport] = useState();
  const [filterDate, setFilterDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  function reportCashFlow() {
    setLoading(true);
    generateReportCashFlow(dayjs(filterDate).format("YYYY-MM-DD"))
      .then((response) => {
        setReport(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }

  useEffect(() => {
    reportCashFlow();
  }, [filterDate]);

  return (
    <div>
      <button
        type="button"
        onClick={() => reportCashFlow(false)}
        className="btn"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <i className="fa fa-dollar-sign me-1"></i> Fechar caixa
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-lg-flex">
                <h5 className="modal-title" id="exampleModalLabel">
                  Fechamento de caixa
                </h5>
                <div className="col-6 col-lg-3 mt-lg-0 mt-2 mx-lg-3">
                  <ReactDatePicker
                    showIcon
                    icon="fa fa-plus"
                    className=" form-control"
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    maxDate={new Date()}
                    todayButton="Hoje"
                    required
                  />
                </div>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {!loading ? (
                <AccordionFechamentoCaixa
                  report={report}
                  title={dayjs(filterDate).format("DD/MM/YYYY")}
                />
              ) : (
                <div className="d-flex justify-content-center p-3">
                  <CDBSpinner dark />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FechamentoCaixaModal;
