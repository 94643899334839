import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listAllCompanies(delay = true) {
  const url = `${API_URL}/companies`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}


export async function getCompanyInfo() {
  const url = `${API_URL}/companies/info`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createCompany(data) {
  const url = `${API_URL}/companies`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateCompany(id, data) {
  const url = `${API_URL}/companies/${id}`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function adminUpdateCompany(data) {
  const url = `${API_URL}/companies/${data.id}/admin`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}
