import React, { useState } from "react";
import { useRef } from "react";
import { adminUpdateCompany } from "../../../services/CompanyService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import removerSpecialChars from "../../../util/StringUtils";
import ReactDatePicker from "react-datepicker";
const dayjs = require("dayjs");

export const EditarEmpresa = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(
    new Date(props.company.subscriptionEndDate + " 00:00:00")
  );
  const inputCnpj = useRef("");
  const inputCorporateName = useRef("");
  const inputFantasyName = useRef("");
  const inputAddress = useRef("");
  const inputWhatsAppNumber = useRef("");
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id: props.company.id,
      documentNumber: removerSpecialChars(inputCnpj.current.value),
      corporateName: inputCorporateName.current.value,
      fantasyName: inputFantasyName.current.value,
      address: inputAddress.current.value,
      whatsAppNumber: removerSpecialChars(inputWhatsAppNumber.current.value),
      subscriptionEndDate: dayjs(subscriptionEndDate).format("YYYY-MM-DD"),
    };

    adminUpdateCompany(data)
      .then((response) => {
        props.listCompanies(false);
        toast.success(response);
        setIsOpen(false);
      })
      .catch((error) => {
        console.error("[updateCompany] ", error);
        toast.error("Não foi possivel atualizar dados da empresa");
      });
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
      >
        Editar
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados da empresa</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-12 mb-3">
                <label htmlFor="razaoSocial" className="form-label bold">
                  Razão Social
                </label>
                <input
                  ref={inputCorporateName}
                  defaultValue={props.company.corporateName}
                  type="text"
                  id="razaoSocial"
                  className="form-control"
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="nomeFantasia" className="form-label bold">
                  Nome Fantasia
                </label>
                <input
                  ref={inputFantasyName}
                  defaultValue={props.company.fantasyName}
                  type="text"
                  id="nomeFantasia"
                  className="form-control"
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  CNPJ
                </label>
                <ReactInputMask
                  ref={inputCnpj}
                  defaultValue={props.company.documentNumber}
                  type="text"
                  id="cnpj"
                  mask="99.999.999/9999-99"
                  className="form-control"
                  required
                  disabled
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  Endereço
                </label>
                <input
                  defaultValue={props.company.address}
                  ref={inputAddress}
                  type="text"
                  id="address"
                  className="form-control"
                  disabled
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  Whats App da empresa
                </label>
                <ReactInputMask
                  defaultValue={props.company.whatsAppNumber}
                  ref={inputWhatsAppNumber}
                  type="text"
                  id="address"
                  className="form-control"
                  mask="(99) 99999-9999"
                  required
                  disabled
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="subscriptionEndDate"
                  className="form-label bold"
                >
                  Vencimento assinatura
                </label>
                <div>
                  <ReactDatePicker
                    id="subscriptionEndDate"
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    onChange={(data) => setSubscriptionEndDate(data)}
                    onMonthChange={(data) => setSubscriptionEndDate(data)}
                    minDate={new Date()}
                    selected={subscriptionEndDate}
                    required
                  />
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Salvar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default EditarEmpresa;
