import React, { useState } from "react";
import { useRef } from "react";
import { updateCustomer } from "../../../../services/CustomerService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import removerSpecialChars from "../../../../util/StringUtils";

export const EditarClienteModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputPhone = useRef("");
  const inputName = useRef("");
  const inputAddress = useRef("");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(props.customer.notify);
  const [remarketingEnabled, setRemarketingEnabled] = useState(
    props.customer.remarketingEnabled
  );

  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      phone: "55" + removerSpecialChars(inputPhone.current.value),
      name: inputName.current.value,
      address: inputAddress.current.value,
      notify,
      remarketingEnabled,
    };

    updateCustomer(props.customer.id, data)
      .then((response) => {
        setIsOpen(false);
        props.listCustomers(false);
        toast.success("Dados do cliente atualizado");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel atualizar cliente");
      });
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
      >
        Editar
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados do cliente</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-4 mb-3">
                <label htmlFor="customerName" className="form-label">
                  Nome
                </label>
                <input
                  ref={inputName}
                  defaultValue={props.customer.name}
                  type="text"
                  id="customerName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-3 mb-3">
                <label htmlFor="customerPhone" className="form-label">
                  Telefone
                </label>
                <ReactInputMask
                  ref={inputPhone}
                  defaultValue={props.customer.phone.substring(2)}
                  type="phone"
                  mask="(99) 99999-9999"
                  id="customerPhone"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-lg-4">
                <label className="form-label bold">Notificar no WhatsApp</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switchNotify"
                      checked={notify}
                      onChange={() => setNotify(!notify)}
                    />
                    <label className="form-check-label" for="switchNotify">
                      {notify ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-lg-0 mt-3">
                <label htmlFor="address" className="form-label">
                  Endereço
                </label>
                <input
                  ref={inputAddress}
                  defaultValue={props.customer.address}
                  type="text"
                  id="address"
                  className="form-control"
                  placeholder="Opcional"
                />
              </div>

              <div className="col-lg-12 mt-3">
                <label className="form-label bold">Lembrete de Serviço</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      id="switchRemarketing"
                      className="form-check-input"
                      type="checkbox"
                      checked={remarketingEnabled}
                      onChange={() =>
                        setRemarketingEnabled(!remarketingEnabled)
                      }
                    />
                    <label className="form-check-label" for="switchRemarketing">
                      {remarketingEnabled ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Salvar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};
export default EditarClienteModal;
