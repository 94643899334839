import React, { useState } from "react";
import { useRef } from "react";
import { createCar } from "../../../services/CarService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import carBrands from "../../../consts/CarBrands";
import ReactSelect from "react-select";

export const NovoVeiculo = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [vehicleBrand, setVehicleBrand] = useState();
  const inputLicensePlate = useRef("");
  const inputColor = useRef("");
  const inputModel = useRef("");
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const body = {
      vehicleBrand: vehicleBrand.key,
      vehicleColor: inputColor.current.value,
      vehicleModel: inputModel.current.value,
      licensePlate: inputLicensePlate.current.value,
    };

    newVehicle(body);
  }

  function newVehicle(body) {
    createCar(body)
      .then((response) => {
        setLoading(false);
        setIsOpen(false);
        toast.success("Veículo cadastrado");
        props.listVehicles();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data) toast.warning(error.response.data);
        else toast.error("Não foi possivel cadastrar novo veículo");
      });
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i> Novo veículo
      </button>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          backdrop="static"
          size="lg"
        >
          <ModalHeader toggle={toggle}>Novo veículo</ModalHeader>

          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-lg-2 mb-3">
                  <label className="form-label bold">Placa</label>
                  <input
                    ref={inputLicensePlate}
                    className="form-control text-uppercase"
                    minLength={7}
                    maxLength={7}
                    required
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label">Marca</label>
                  <ReactSelect
                    id="selectBrand"
                    name="brands"
                    classNamePrefix="select"
                    onChange={(brand) => setVehicleBrand(brand)}
                    value={vehicleBrand}
                    options={carBrands.map((brand, index) => ({
                      value: brand.replace("_", " "),
                      key: brand,
                      label: brand.replace("_", " "),
                    }))}
                    className="basic-multi-select"
                    placeholder=""
                    required
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label className="form-label">Modelo</label>
                  <input ref={inputModel} className="form-control" required />
                </div>

                <div className="col-lg-3 mb-3">
                  <label className="form-label">Cor</label>
                  <input ref={inputColor} className="form-control" placeholder="Opcional" />
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              {!loading ? (
                <>
                  <button className="btn btn-dark">Adicionar</button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="btn btn-danger"
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </ModalFooter>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default NovoVeiculo;
