import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listAllParts() {
  const url = `${API_URL}/parts`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createPart(data) {
  const url = `${API_URL}/parts`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updatePart(data) {
  const url = `${API_URL}/parts/${data.id}`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deletePart(id) {
  const url = `${API_URL}/parts/${id}`;
  const response = await axiosInstance.delete(url, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

