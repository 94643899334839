import getAuthHeaders from "../util/HeadersUtil";
import axiosInstance from "../interceptors/axiosInterceptor";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function findCustomerByNameOrPhone(nameOrPhone) {
  const url = `${API_URL}/customers/filter-by-phone-or-name?nameOrPhone=${nameOrPhone}`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function findCustomerByPhone(phone) {
  const url = `${API_URL}/customers/phone/${phone}`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function listAllCustomersPageable(delay = true) {
  const url = `${API_URL}/customers/pageable`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function listAllCustomers(delay = true) {
  const url = `${API_URL}/customers`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function listAllCustomersByPage(pagenumber) {
  const url = `${API_URL}/customers/pageable?page=${pagenumber}`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function createCustomer(data) {
  const url = `${API_URL}/customers`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateCustomer(customerId, data) {
  const url = `${API_URL}/customers/${customerId}`;
  const response = await axiosInstance.patch(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deleteCustomer(customerId) {
  const url = `${API_URL}/customers/${customerId}`;
  const response = await axiosInstance.delete(url, {
    headers: getAuthHeaders(),
  });
  return response.data;
}
