import React, { useState } from "react";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { sendCashFlowResumePDF } from "../../../services/ReportService";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner";

export const AccordionFechamentoCaixa = (props) => {
  const [loading, setLoading] = useState();

  function sendResumeToWhatsapp() {
    setLoading(true);
    props.report.title = props.title;
    sendCashFlowResumePDF(props.report)
      .then((response) => {
        toast.success(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Não foi possivel enviar Fechamento de Caixa");
        setLoading(false);
      });
  }

  return (
    <div className="accordion-body">
      <div className="row">
        <div className="col-lg-4">
          <p
            className={
              props.report && props.report.paymentMethods.length > 0
                ? "bold mb-1"
                : "bold m-0"
            }
          >
            Entradas
          </p>

          {props.report && props.report.paymentMethods.length > 0 ? (
            props.report.paymentMethods.map((payment) => (
              <div key={payment.name}>
                <div> {payment.name}</div>

                <p>
                  <i className="fas fa-angle-up p-1 text-success" />
                  {currencyBRFormat(payment.value ?? 0.0)}
                </p>
              </div>
            ))
          ) : (
            <p> {currencyBRFormat(0.0)}</p>
          )}
        </div>

        <div className="col-lg-4">
          <p
            className={
              props.report && props.report.variableExpenses.length > 0
                ? "bold mb-1"
                : "bold m-0"
            }
          >
            Saídas
          </p>

          {props.report && props.report.variableExpenses.length > 0 ? (
            props.report.variableExpenses.map((expense) => (
              <div>
                <el data-bs-toggle="tooltip" data-bs-placement="right">
                  <div>{expense.description} </div>
                </el>
                <p>
                  <i className="fas fa-angle-down p-1 text-danger" />
                  {currencyBRFormat(expense.value ?? 0.0)}
                </p>
              </div>
            ))
          ) : (
            <p> {currencyBRFormat(0.0)}</p>
          )}
        </div>
        <div className="col-lg-4">
          <div className="bold">Veículos atendidos</div>
          <p>
            {props.report && props.report.totalVehicles ? props.report.totalVehicles : 0}
          </p>
        </div>

        <hr />
        <div className="col-lg-4">
          <div className="bold">Total de Entradas</div>
          <p>
            <i className="fas fa-angle-up p-1 text-success" />
            {props.report
              ? currencyBRFormat(props.report.grossRevenue ?? 0.0)
              : 0.0}
          </p>
        </div>
        <div className="col-lg-4">
          <div className="bold">Total de Saídas</div>
          <p>
            <i className="fas fa-angle-down p-1 text-danger" />
            {props.report
              ? currencyBRFormat(props.report.totalVariableExpenses ?? 0.0)
              : 0.0}
          </p>
        </div>
        <div className="col-lg-4">
          <div className="bold">Balanço Final</div>
          <p>
            {props.report
              ? currencyBRFormat(props.report.netRevenue)
              : currencyBRFormat(0.0)}
          </p>
        </div>
        <hr />
        <div className="d-flex">
          {!loading ? (
            <>
              <button className="btn btn-dark" onClick={sendResumeToWhatsapp}>
                <i
                  className="fab fa-whatsapp fa-lg me-2"
                  aria-hidden="true"
                ></i>
                Enviar Fechamento
              </button>
              <button className="btn btn-dark mx-3 d-none">
                <i className="fa fa-download me-2" aria-hidden="true"></i>{" "}
                Baixar
              </button>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionFechamentoCaixa;
