import React from "react";
import { Header } from "./Navbar.style";

const Navbar = (props) => {
  const companyName = localStorage.getItem("companyName");

  return (
    <Header style={{ background: "#333", color: "#fff" }}>
      <div className="p-2 m-2 d-flex justify-content-between">
        <button
          className="btn text-light align-self-start"
          onClick={() => props.setToggled(!props.toggled)}
        >
          <i className="fa fa-bars"></i>
        </button>

       <div className="mx-3">{companyName}</div> 
      </div>
    </Header>
  );
};

export default Navbar;
