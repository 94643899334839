import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { createServiceControll } from "../../../../services/ServiceControllService";
import { toast } from "react-toastify";
import Select from "react-select";
import { findByLicensePlate } from "../../../../services/CarService";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import carBrands from "../../../../consts/CarBrands";
import Spinner from "../../../../components/Spinner";
import CadastrarServicoModal from "./CadastrarServicoModal";
import CadastrarClienteVeiculoModal from "./CadastrarClienteVeiculoModal";
import { findLastQuotationByCustomerId } from "../../../../services/QuotationService";
import { maskPhone } from "../../../../util/StringUtils";

export const ControleServicoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchingCar, setSearchingCar] = useState(false);
  const [selectedServies, setSelectedServies] = useState([]);
  const [selectedParts, setSelectedsParts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState();
  const toggle = () => setIsOpen(!isOpen);
  const inputLicensePlate = useRef("");
  const inputVehicleColor = useRef("");
  const inputVehicleModel = useRef("");
  const inputSelectCustomer = useRef();
  const inputSignValue = useRef("");
  const inputCheckList = useRef("");
  const [loading, setLoading] = useState(false);
  const [modalServicoOpen, setModalServicoOpen] = useState(false);
  const [modalCadastroCliente, setModalCadastroCliente] = useState(false);
  const [vehicleBrand, setVehicleBrand] = useState();
  const [brandSelectDisabled, setBrandSelectDisabled] = useState(false);
  const [quotationId, setQuotationId] = useState();
  const [disableSelectCustomer, setDisableSelectCustomer] = useState(false);
  const [licensePlate, setLicensePlate] = useState("");
  let confirmedQuotation = false;

  useEffect(() => {
    setSelectedServies([]);
    setSelectedCustomer();
    setSelectedEmployee();
    setSelectedsParts([]);
    setSearchingCar(false);
    setVehicleBrand(null);
    setBrandSelectDisabled(false);
  }, [isOpen]);

  function handleChangeCustomer(customer) {
    setSelectedCustomer(customer);
    if (customer) {
      //Desabilitado por disparar onblur da plca do veiculo
      //findQuotation(customer.value);
    } else {
      setSelectedServies([]);
      inputLicensePlate.current.value = "";
    }
  }

  function findQuotation(customerId) {
    if (!confirmedQuotation) {
      findLastQuotationByCustomerId(customerId)
        .then((response) => {
          if (response) {
            const execute = window.confirm(
              "Preencher com os serviços do orçamento do cliente?"
            );
            if (execute) {
              confirmedQuotation = true;
              findVehicle(response.vehiclePlate);
              setSelectedServies(
                response.servicesIds.map((serviceId) =>
                  props.services.find((service) => service.value === serviceId)
                )
              );
              setQuotationId(response.id);
              toast.success(
                "Serviços preenchidos com as informações do orçamento"
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function findVehicle(licensePlate) {
    findByLicensePlate(licensePlate)
      .then((response) => {
        if (response) {
          setSearchingCar(false);
          inputLicensePlate.current.value = response.licensePlate;
          inputVehicleColor.current.value = response.color;
          inputVehicleModel.current.value = response.model;

          setVehicleBrand({
            key: response.brand,
            label: response.brand.replace("_", " "),
            value: response.brand.replace("_", " "),
          });

          if (response.customer) {
            setSelectedCustomer({
              value: response.customer.id,
              label:
                response.customer.name +
                " " +
                maskPhone(response.customer.phone.substring(2)),
            });
            setDisableSelectCustomer(true);
            findQuotation(response.customer.id);
          } else {
            setDisableSelectCustomer(false);
            setSelectedCustomer(null);
          }

          setBrandSelectDisabled(true);
          inputVehicleColor.current.disabled = true;
          inputVehicleModel.current.disabled = true;
          inputSelectCustomer.current.disabled = true;
        } else {
          setSearchingCar(false);
          inputLicensePlate.current.value = licensePlate;
          inputVehicleColor.current.disabled = false;
          inputVehicleModel.current.disabled = false;

          inputVehicleColor.current.value = "";
          inputVehicleModel.current.value = "";

          setVehicleBrand(null);
          setBrandSelectDisabled(false);
          setSelectedCustomer(null);
          setDisableSelectCustomer(false);
          setModalCadastroCliente(true);
        }
      })
      .catch((error) => {
        setSearchingCar(false);
        setVehicleBrand(null);
        setBrandSelectDisabled(false);
        setDisableSelectCustomer(false);
      });
  }

  function handleInputLicensePlate(e) {
    e.preventDefault();
    setSearchingCar(true);
    if (e.target.value) {
      setLicensePlate(e.target.value);
      findVehicle(e.target.value);
    } else {
      setSearchingCar(false);
      inputVehicleColor.current.disabled = false;
      inputVehicleModel.current.disabled = false;
      setBrandSelectDisabled(false);

      inputVehicleColor.current.value = "";
      inputVehicleModel.current.value = "";
      setVehicleBrand(null);
      setSelectedCustomer();
      setDisableSelectCustomer(false);
    }
  }

  const formatServicesLabels = (service) => (
    <>
      <span>{service.label}</span>
      <span> - R$ {service.key}</span>
    </>
  );

  const formatCustomersLabels = (customer) => (
    <>
      <span>{customer.label}</span>
    </>
  );

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      licensePlate: inputLicensePlate.current.value,
      vehicleBrand: vehicleBrand.key,
      vehicleColor: inputVehicleColor.current.value,
      vehicleModel: inputVehicleModel.current.value,
      servicesIds: selectedServies.map((service) => service.value),
      partsIds: selectedParts.map((part) => part.value),
      userId: localStorage.getItem("userId"),
      employeeId: selectedEmployee.value,
      customerId: selectedCustomer ? selectedCustomer.value : null,
      signValue: inputSignValue.current.value ?? 0,
      checklist: inputCheckList.current.value,
      quotationId,
    };

    createServiceControll(data)
      .then((response) => {
        setIsOpen(false);
        props.listControllServices(false);
        toast.success("Novo controle de serviço criado");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Não foi possivel criar controle de serviço");
      });
  }

  function calculateTotalSelectServices() {
    return currencyBRFormat(
      selectedServies
        .concat(selectedParts)
        .map((service) => service.key)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    );
  }

  return (
    <div>
      <CadastrarServicoModal
        setModalServicoOpen={setModalServicoOpen}
        modalServicoOpen={modalServicoOpen}
        listServices={props.listServices}
      />

      {modalCadastroCliente && (
        <CadastrarClienteVeiculoModal
          setModalCadastroCliente={setModalCadastroCliente}
          open={modalCadastroCliente}
          licensePlate={licensePlate}
          listCustomers={props.listCustomers}
          findVehicle={findVehicle}
        />
      )}

      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn no-hover"
      >
        <i className="fa fa-plus me-1"></i> Novo
      </button>
      <Modal isOpen={isOpen} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Novo Controle de Serviço</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-lg-4 mb-3 d-none">
                <label htmlFor="listaServicos" className="form-label bold">
                  Valor do sinal R$
                </label>
                <input
                  type="number"
                  defaultValue={0}
                  ref={inputSignValue}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <label className="form-label bold">Dados do veículo</label>

              {searchingCar ? (
                <Spinner />
              ) : (
                <>
                  <div className="col-lg-2 mb-3">
                    <label className="form-label">Placa</label>
                    <input
                      onBlur={handleInputLicensePlate}
                      ref={inputLicensePlate}
                      type="text"
                      minLength={7}
                      maxLength={7}
                      className="form-control text-uppercase"
                      required
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Marca</label>
                    <Select
                      name="brands"
                      classNamePrefix="select"
                      onChange={(brand) => setVehicleBrand(brand)}
                      value={vehicleBrand}
                      options={carBrands.map((brand, index) => ({
                        value: brand.replace("_", " "),
                        key: brand,
                        label: brand.replace("_", " "),
                      }))}
                      className="basic-multi-select"
                      placeholder=""
                      isDisabled={brandSelectDisabled}
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label htmlFor="modeloCarro" className="form-label">
                      Modelo
                    </label>
                    <input
                      ref={inputVehicleModel}
                      id="modeloCarro"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label htmlFor="corCarro" className="form-label">
                      Cor
                    </label>
                    <input
                      id="corCarro"
                      ref={inputVehicleColor}
                      className="form-control"
                      placeholder="Opcional"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="col-lg-12 mt-3">
              <label className="form-label bold">Cliente</label>

              <div className="mt-1">
                <Select
                  ref={inputSelectCustomer}
                  onChange={handleChangeCustomer}
                  id="customerSelect"
                  name="customers"
                  classNamePrefix="select"
                  value={selectedCustomer}
                  options={props.customers}
                  className="basic-multi-select"
                  placeholder="Escolha um cliente (Opcional)"
                  isDisabled={disableSelectCustomer}
                  formatOptionLabel={formatCustomersLabels}
                  isClearable
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Checklist do veículo
                  </label>

                  <button
                    onClick={() =>
                      (inputCheckList.current.value =
                        "Veículo sem avarias ou detalhes")
                    }
                    className="btn btn-success mx-lg-3 mx-2"
                    type="button"
                    style={{ fontSize: "0.8em" }}
                    title="Texto padrão para checklist quando está tudo ok com o veículo, sem detalhes para mencionar"
                  >
                    Checklist sem avarias
                  </button>
                </div>

                <textarea
                  ref={inputCheckList}
                  className="form-control mt-2"
                  placeholder="Informe avarias e/ou detalhes identificados no veículo como aranhões, amassados, pintura etc"
                  required
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <label htmlFor="listaServicos" className="form-label bold">
                  Funcionário
                </label>
                <Select
                  onChange={(data) => setSelectedEmployee(data)}
                  id="listaUsuarios"
                  name="services"
                  classNamePrefix="select"
                  options={props.employees}
                  className="basic-multi-select"
                  placeholder="Escolha o funcionário responsável pelo serviço"
                  required
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Serviços a fazer
                  </label>
                  <button
                    onClick={() => setModalServicoOpen(true)}
                    type="button"
                    className="btn btn-dark mx-lg-3 mx-2"
                    style={{ fontSize: "0.8em" }}
                    title="Caso não encontre o serviço na lista de serviços abaixo este botão para cadastrar um novo serviço rapidamente"
                  >
                    Cadastrar serviço
                  </button>
                </div>
                <div className="mt-2">
                  <Select
                    onChange={(data) => setSelectedServies(data)}
                    id="listaServicos"
                    isMulti
                    name="services"
                    classNamePrefix="select"
                    value={selectedServies}
                    options={props.services}
                    className="basic-multi-select"
                    formatOptionLabel={formatServicesLabels}
                    placeholder="Escolha os serviços a serem realizados"
                    isClearable
                    required
                  />

                  <div className="text-end mt-2 bold">
                    Total: {calculateTotalSelectServices()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row d-none">
              <div className="col-lg-12">
                <label htmlFor="listaPeças" className="form-label bold">
                  Peças
                </label>
                <div>
                  <Select
                    onChange={(data) => setSelectedsParts(data)}
                    id="listaPeças"
                    isMulti
                    name="parts"
                    classNamePrefix="select"
                    options={props.parts}
                    className="basic-multi-select"
                    formatOptionLabel={formatServicesLabels}
                    placeholder="Opcional"
                    isClearable
                  />
                </div>
                <div className="text-end mt-2 bold">
                  Total: {calculateTotalSelectServices()}
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Criar
                </button>

                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <Spinner />
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default ControleServicoModal;
