import React from "react";
import { CDBTable, CDBTableBody, CDBTableHeader } from "cdbreact";
import EditarFormaPagamentoModal from "./EditarFormaPagamentoModal";

export const AccordionTaxasFormaPagamento = (props) => {

  return (
    <div className="card p-3 border-top-0">
  
      <div className="">
      <CDBTable responsiveSm responsiveMd>
        <CDBTableHeader>
          <tr>
            <th>Nome</th>
            <th>Taxa</th>
            <th>Ações</th>
          </tr>
        </CDBTableHeader>
        <CDBTableBody>
          {props.paymentMethods.map((pay) => (
            <tr key={pay.id}>
              <td>{pay.name}</td>
              <td>{pay.fee}%</td>

              <td>
                <div className="d-flex">
                  <EditarFormaPagamentoModal pay={pay} listPayments={props.listPayments}/>
                </div>
              </td>
            </tr>
          ))}
        </CDBTableBody>
      </CDBTable>
      </div>
     
    </div>
  );
};

export default AccordionTaxasFormaPagamento;
