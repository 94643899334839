export const carBrands = [
  "ACURA",
  "ASTON_MARTIN",
  "AUDI",
  "BENTLEY",
  "BMW",
  "BUGATTI",
  "BYD",
  "BUICK",
  "CADILLAC",
  "CHEVROLET",
  "CHERY",
  "CHRYSLER",
  "CITROEN",
  "DACIA",
  "DODGE",
  "DAEWOO",
  "DAIHATSU",
  "DATSUN",
  "DUCATI",
  "FERRARI",
  "FIAT",
  "FISKER",
  "FORD",
  "GMC",
  "GWM",
  "HAVAL",
  "HARLEY_DAVIDSON",
  "HONDA",
  "HYUNDAI",
  "INFINITI",
  "ISUZU",
  "JAC",
  "JAGUAR",
  "JEEP",
  "KAWASAKI",
  "KIA",
  "KTM",
  "LIFAN",
  "LANCIA",
  "LAND_ROVER",
  "LEXUS",
  "MASERATI",
  "MAZDA",
  "MCLAREN",
  "MERCEDES_BENZ",
  "MINI",
  "MITSUBISHI",
  "NISSAN",
  "PEUGEOT",
  "PONTIAC",
  "PORSCHE",
  "RAM",
  "RENAULT",
  "ROLLS_ROYCE",
  "ROYAL_ENFIELD",
  "SAAB",
  "SEAT",
  "SKODA",
  "SMART",
  "SSANGYONG",
  "SUBARU",
  "SUZUKI",
  "TESLA",
  "TOYOTA",
  "TRIUMPH",
  "VAUXHALL",
  "VOLKSWAGEN",
  "VOLVO",
  "WULING",
  "YAMAHA",
];

export default carBrands;
