import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listAllOnlyIdsAndNames() {
  const serviceControllUrl = `${API_URL}/services/ids-and-names`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllServicesByPage(pageNumber) {
  const serviceControllUrl = `${API_URL}/services/pageable?page=${pageNumber}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllServices() {
  const serviceControllUrl = `${API_URL}/services`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllServicos(delay = true) {
  const url = `${API_URL}/services`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createServico(data) {
  const url = `${API_URL}/services`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateServico(data) {
  const url = `${API_URL}/services/${data.id}`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deleteServico(id) {
  const url = `${API_URL}/services/${id}`;
  const response = await axiosInstance.delete(url, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

