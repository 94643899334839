import React, { useState } from "react";
import { useRef } from "react";
import { createUser } from "../../../services/UserService";
import { toast } from "react-toastify";
import Select from "react-select";
import ROLES from "./roles";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const AdicionarUsuarioModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState();
  const inputName = useRef("");
  const inputEmail = useRef("");
  const inputPassword = useRef("");
  const [loading, setLoading] = useState(false);
  const [comission, setComission] = useState();
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      name: inputName.current.value,
      email: inputEmail.current.value,
      password: inputPassword.current.value,
      roles: [roles.key],
      comission: comission ? comission : 0,
    };

    try {
      createUser(data)
        .then((response) => {
          props.listUsers(false);
          toast.success(response);
          setIsOpen(false);
        })
        .catch((error) => {
          if (error.response.status == 422) toast.error(error.response.data);
          else toast.error("Não foi possivel adicionar usuário");
        });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }
  const formatRoleLabels = (role) => (
    <>
      <span>{role.value}</span>
    </>
  );

  return (
    <>
      <button type="button" className="btn" onClick={() => setIsOpen(true)}>
        <i className="fa fa-plus me-2"></i> Novo funcionário
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de novo funcionário</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-6 mb-3">
                <label htmlFor="userName" className="form-label bold">
                  Nome
                </label>
                <input
                  id="userName"
                  ref={inputName}
                  type="text"
                  className="form-control"
                  placeholder="Nome do usuário"
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="userEmail" className="form-label bold">
                  E-mail
                </label>
                <input
                  id="userEmail"
                  ref={inputEmail}
                  type="email"
                  className="form-control"
                  placeholder="E-mail do usuário"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="userPass" className="form-label bold">
                  Senha
                </label>
                <input
                  id="userPass"
                  autoComplete="new-password"
                  ref={inputPassword}
                  type="password"
                  className="form-control"
                  placeholder="Senha do usuário"
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="userRoles" className="form-label bold">
                  Função
                </label>
                <Select
                  id="userRoles"
                  onChange={setRoles}
                  name="roles"
                  classNamePrefix="select"
                  options={ROLES}
                  formatOptionLabel={formatRoleLabels}
                  className="basic-multi-select"
                  placeholder="Adicione um perfil para o usuário"
                  required
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="userRoles" className="form-label bold">
                  Comissão %
                </label>
                <input
                  onChange={(e) => setComission(e.target.value)}
                  value={comission}
                  className="form-control"
                  type="number"
                  max={100}
                  min={0}
                  maxLength={2}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Adicionar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default AdicionarUsuarioModal;
