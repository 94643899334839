import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listAllPromotions() {
  const url = `${API_URL}/promotions`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function createPromotion(data) {
  const url = `${API_URL}/promotions`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function updatePromotion(data) {
  const url = `${API_URL}/promotions`;
  const response = await axiosInstance.put(url, data, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function sendFlashPromotion(promotionId) {
  const url = `${API_URL}/promotions/${promotionId}/send`;
  const response = await axiosInstance.post(
    url,
    {},
    {
      headers: getAuthHeaders(),
    }
  );

  return response.data;
}

export async function deletePromotionById(promotionId) {
  const url = `${API_URL}/promotions/${promotionId}`;
  const response = await axiosInstance.delete(url, {
    headers: getAuthHeaders(),
  });

  return response.data;
}
