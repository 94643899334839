import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBSpinner } from "cdbreact";
import {
  listAllSalesByStatus,
  listAllSalesByStatusAndPage,
} from "../../../services/SaleService";
import { listAllCustomers } from "../../../services/CustomerService";
import FechamentoCaixaModal from "./modal/FechamentoCaixaModal";
import { maskPhone } from "../../../util/StringUtils";
import Select from "react-select";
import CorrigirVendaModal from "./modal/CorrigirVendaModal";
import VendasAbertasTable from "./tables/VendasAbertasTable";
import VendasPagasTable from "./tables/VendasPagasTable";
import VendasReceberTable from "./tables/VendaReceberTable";
import { useLocation } from "react-router-dom";

const statusList = [
  { key: "OPENED", label: "Abertas", value: "Abertas" },
  { key: "PAID", label: "Pagas", value: "Pagas" },
  { key: "RECEIVABLES", label: "A receber", value: "A receber" },
];

export const Caixa = () => {
  const [sales, setSales] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [toggled, setToggled] = useState(false);
  const [status, setStatus] = useState(statusList[0]);
  const [pageInfo, setPageInfo] = useState();
  const [openCorrigirVendaModal, setOpenCorrigirVendaModal] = useState(false);
  const [selectedSale, setSelectedSale] = useState();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    listCustomers();
    setStatusIfParamExists();
  }, []);

  useEffect(() => {
    listSales(status.key);
  }, [status]);

  function setStatusIfParamExists() {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("status")) {
      const status = statusList.find((s) => s.label === searchParams.get("status"))
      setStatus(status);
    }
  }

  function listSales(status, customerId) {
    setIsLoading(true);
    listAllSalesByStatus(status, customerId)
      .then((response) => {
        setIsLoading(false);
        setSales(response.data);
        setPageInfo(response.pageInfo);
      })
      .catch((error) => setIsLoading(false));
  }

  function listSalesPage(page) {
    setIsLoading(true);
    listAllSalesByStatusAndPage(status.key, page)
      .then((response) => {
        setIsLoading(false);
        setSales(response.data);
        setPageInfo(response.pageInfo);
      })
      .catch((error) => setIsLoading(false));
  }

  function listCustomers() {
    listAllCustomers()
      .then((response) => {
        setCustomers(
          response.map((customer) => ({
            value: customer.id,
            label: customer.name,
            key: maskPhone(customer.phone.substring(2)),
          }))
        );
      })
      .catch((error) => {});
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function handleCorrigirVenda(sale) {
    setSelectedSale(sale);
    setOpenCorrigirVendaModal(true);
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />

        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3 d-flex">
                <h3>Caixa</h3>
              </div>

              <div className="d-flex mt-3 mb-3">
                <div>
                  <FechamentoCaixaModal />
                  {openCorrigirVendaModal && (
                    <CorrigirVendaModal
                      sale={selectedSale}
                      setStatus={setStatus}
                      open={openCorrigirVendaModal}
                      setOpen={setOpenCorrigirVendaModal}
                    />
                  )}
                </div>
                <div className="vertical-line me-lg-0 me-3"></div>
                <div className="d-lg-flex">
                  <div className="align-self-center mt-0 mx-lg-3">
                    <label>Filtrar vendas</label>
                  </div>
                  <div className="mt-lg-0 mt-2">
                    <Select
                      id="statusSelect"
                      name="status"
                      classNamePrefix="select"
                      value={status}
                      onChange={(s) => setStatus(s)}
                      options={statusList}
                      className="basic-multi-select"
                      placeholder=""
                      required
                    />
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="d-flex justify-content-center p-3">
                  <CDBSpinner dark />
                </div>
              ) : (
                <>
                  {status &&
                    switchSaleTable(
                      status.key,
                      sales,
                      listSales,
                      pageInfo,
                      listSalesPage,
                      handleCorrigirVenda,
                      customers
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Caixa;

const switchSaleTable = (
  status,
  sales,
  listSales,
  pageInfo,
  listSalesPage,
  handleCorrigirVenda,
  customers
) => {
  switch (status) {
    case "OPENED":
      return (
        <VendasAbertasTable
          sales={sales}
          listSales={listSales}
          customers={customers}
        />
      );

    case "PAID":
      return (
        <VendasPagasTable
          sales={sales}
          pageInfo={pageInfo}
          goToPage={listSalesPage}
          handleCorrigirVenda={handleCorrigirVenda}
        />
      );
    case "RECEIVABLES":
      return (
        <VendasReceberTable
          sales={sales}
          pageInfo={pageInfo}
          customers={customers}
          goToPage={listSalesPage}
        />
      );
    default:
      return <></>;
  }
};
