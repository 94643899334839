import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function listPaymentMethodsByCompany(delay = true) {
  const paymentMethodUrl = `${API_URL}/payment-methods`;
  const response = await axiosInstance.get(paymentMethodUrl, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateFee(data) {
  const paymentMethodUrl = `${API_URL}/payment-methods/${data.id}`;
  const response = await axiosInstance.put(paymentMethodUrl, {fee: data.fee}, {
    headers: getAuthHeaders(),
  });
  return response.data;
}