import { useRef, useState } from "react";
import Select from "react-select";

const MultiPayment = (props) => {
  const inputPaymentMethod = useRef("");
  const [paymentValue, setPaymentValue] = useState(0);
  const formatLabels = (payment) => (
    <>
      <span>
        {payment.name} R$ {payment.value}
      </span>
    </>
  );

  return (
    <>
      <div className="d-flex">
        <div className="col-lg-7">
          <select
            id="selectPayMethod"
            ref={inputPaymentMethod}
            className="form-select"
            aria-label="Default select example"
            required
          >
            <option value="MONEY">Dinheiro</option>
            <option value="CREDIT">Crédito à vista</option>
            <option value="INSTALLMENT_CREDIT">Crédito parcelado</option>
            <option value="DEBIT">Débito</option>
            <option value="PIX">Pix</option>
          </select>
        </div>

        <div className="col-lg-3 mx-2">
          <input
            className="form-control"
            value={paymentValue}
            type="number"
            onChange={(e) => setPaymentValue(e.target.value)}
          />
        </div>

        <div className="col-lg-2 d-flex">
          <button
            className="btn btn-dark"
            type="button"
            onClick={() => props.addPayment(inputPaymentMethod, paymentValue)}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div className="mt-2">
        <Select
          onChange={(data) => props.setPaymentList(data)}
          id="listaPagamentos"
          isMulti
          value={props.paymentList}
          name="pagamentos"
          classNamePrefix="select"
          className="basic-multi-select"
          formatOptionLabel={formatLabels}
          placeholder="Adicione formas de pagamento"
          required
        />
      </div>
    </>
  );
};

export default MultiPayment;
