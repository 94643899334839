import React, { useState } from "react";
import { useRef } from "react";
import {
  createCustomer,
  findCustomerByPhone,
} from "../../../../services/CustomerService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import removerSpecialChars from "../../../../util/StringUtils";
import ReactInputMask from "react-input-mask";
import ReactSelect from "react-select";
import carBrands from "../../../../consts/CarBrands";
import { findByLicensePlate } from "../../../../services/CarService";

export const ClienteForm = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputPhone = useRef("");
  const inputName = useRef("");
  const inputAddress = useRef("");
  const addButton = useRef("");
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const [vehicleBrand, setVehicleBrand] = useState();
  const inputLicensePlate = useRef("");
  const inputVehicleColor = useRef("");
  const inputVehicleModel = useRef("");
  const [brandSelectDisabled, setBrandSelectDisabled] = useState(false);
  const [searchingCar, setSearchingCar] = useState(false);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      companyDocumentNumber: localStorage.getItem("companyDocumentNumber"),
      phone: "55" + removerSpecialChars(inputPhone.current.value),
      name: inputName.current.value,
      inputAddress: inputAddress.current.value,
      notify,
      vehicle: {
        vehicleColor: inputVehicleColor.current.value,
        vehicleModel: inputVehicleModel.current.value,
        vehicleBrand: vehicleBrand.key,
        licensePlate: inputLicensePlate.current.value
      }
    };

    createCustomer(data)
      .then((response) => {
        props.listCustomers(false);
        setIsOpen(false);
        toast.success("Cliente adicionado");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.status)
          toast.error(error.response.data);
        else toast.error("Não foi possivel adicionar cliente");
      });
  }

  function findByPhone() {
    findCustomerByPhone("55" + removerSpecialChars(inputPhone.current.value))
      .then((response) => {
        if (response) {
          inputName.current.value = response.name;
          inputAddress.current.value = response.address;
          addButton.current.disabled = true;
          toast.warning("Cliente já cadastrado");
        } else {
          addButton.current.disabled = false;
          inputName.current.value = "";
          inputAddress.current.value = "";
        }
      })
      .catch((error) => {});
  }

  function findVehicle(licensePlate) {
    findByLicensePlate(licensePlate)
      .then((response) => {
        if (response) {
          setSearchingCar(false);
          inputLicensePlate.current.value = response.licensePlate;
          inputVehicleColor.current.value = response.color
          inputVehicleModel.current.value = response.model;

          setVehicleBrand({
            key: response.brand,
            label: response.brand.replace("_", " "),
            value: response.brand.replace("_", " "),
          });

          setBrandSelectDisabled(true);
          inputVehicleColor.current.disabled = true;
          inputVehicleModel.current.disabled = true;
        } else {
          setSearchingCar(false);
          inputLicensePlate.current.value = licensePlate;
          inputVehicleColor.current.disabled = false;
          inputVehicleModel.current.disabled = false;

          inputVehicleColor.current.value = "";
          inputVehicleModel.current.value = "";
        }
      })
      .catch((error) => {
        setSearchingCar(false);
      });
  }

  function handleInputLicensePlate(e) {
    e.preventDefault();
    setSearchingCar(true);
    if (e.target.value) {
      findVehicle(e.target.value);
    } else {
      setSearchingCar(false);
      inputVehicleColor.current.disabled = false;
      inputVehicleModel.current.disabled = false;
      setBrandSelectDisabled(false);

      inputVehicleColor.current.value = "";
      inputVehicleModel.current.value = "";
      setVehicleBrand(null);
    }
  }
  
  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i> Novo cliente
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de novo cliente</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-3 mb-3">
                <label htmlFor="customerPhone" className="form-label">
                  Telefone
                </label>
                <ReactInputMask
                  ref={inputPhone}
                  type="phone"
                  mask="(99) 99999-9999"
                  onBlur={findByPhone}
                  id="customerPhone"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-4">
                <label htmlFor="customerName" className="form-label">
                  Nome
                </label>
                <input
                  ref={inputName}
                  type="text"
                  id="customerName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-4">
                <label className="form-label bold">Notificar no WhatsApp</label>

                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="switchPaymentMethod"
                    checked={notify}
                    onChange={() => setNotify(!notify)}
                  />
                  <label className="form-check-label" for="switchPaymentMethod">
                    {notify ? "Sim" : "Não"}
                  </label>
                </div>
              </div>

              <div className="col-lg-12 mt-lg-0 mt-3">
                <label htmlFor="address" className="form-label">
                  Endereço
                </label>
                <input
                  ref={inputAddress}
                  type="text"
                  id="address"
                  className="form-control"
                  placeholder="Opcional"
                />
              </div>

              <div className="row mt-3">
                <div className="col-12 mb-3">
                  <label className="bold">Dados do Veículo</label>
                </div>

                <div className="col-lg-2">
                  <label className="form-label">Placa</label>
                  <input
                    onBlur={handleInputLicensePlate}
                    ref={inputLicensePlate}
                    className="form-control text-uppercase"
                    minLength={7}
                    maxLength={7}
                    required
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form-label">Marca</label>
                  <ReactSelect
                    id="selectBrand"
                    name="brands"
                    classNamePrefix="select"
                    onChange={(brand) => setVehicleBrand(brand)}
                    value={vehicleBrand}
                    options={carBrands.map((brand, index) => ({
                      value: brand.replace("_", " "),
                      key: brand,
                      label: brand.replace("_", " "),
                    }))}
                    className="basic-multi-select"
                    placeholder=""
                    required
                  />
                </div>
                <div className="col-lg-3">
                  <label className="form-label">Modelo</label>
                  <input
                    id="carModel"
                    ref={inputVehicleModel}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="col-lg-3">
                  <label className="form-label">Cor</label>
                  <input
                    ref={inputVehicleColor}
                    className="form-control"
                    placeholder="Opcional"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {!loading ? (
              <>
                <button ref={addButton} type="submit" className="btn btn-dark">
                  Adicionar
                </button>

                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};
export default ClienteForm;
