import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function findLastQuotationByCustomerId(customerId) {
  const loginUrl = `${API_URL}/quotations/customer/${customerId}`;
  const response = await axiosInstance.get(loginUrl, { headers: getAuthHeaders() });
  return response.data;
}
