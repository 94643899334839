import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import PaginationBootstrap from "../../../../components/PaginationBootstrap";
const dayjs = require("dayjs");

const VendasPagasTable = (props) => {
  return (
    <>
      <>
        <CDBTable responsiveSm responsiveMd>
          <CDBTableHeader>
            <tr>
              <th>Data</th>
              <th>Veículo</th>
              <th>Cliente</th>
              <th>Forma Pagamento</th>
              <th>Total</th>
              <th>Ações</th>
            </tr>
          </CDBTableHeader>
          <CDBTableBody>
            {props.sales &&
              props.sales.map((sale) => (
                <tr key={sale.id}>
                  <td>{dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                  <td>{sale.vehicleInfo}</td>
                  <td>
                    {sale.customer ? sale.customer.name : "Não identificado"}
                  </td>
                  <td>{sale.paymentMethod}</td>
                  <td className="bold">
                    {currencyBRFormat(
                      parseFloat(sale.total) +
                        sale.adtionalValue -
                        sale.discount
                    )}
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => props.handleCorrigirVenda(sale)}
                      className="btn btn-dark"
                    >
                      Corrigir
                    </button>
                  </td>
                </tr>
              ))}
          </CDBTableBody>
        </CDBTable>
      </>
      <PaginationBootstrap
        pageInfo={props.pageInfo}
        goToPage={props.goToPage}
      />
    </>
  );
};

export default VendasPagasTable;
