import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { createServico } from "../../../../services/ServicoService";
import { toast } from "react-toastify";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const CadastrarServicoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [finalValue, setFinalValue] = useState(0.0);
  const [estimatedMinutes, setEstimatedMinutes] = useState(0);
  const [estimatedDays, setEstimatedDays] = useState(0);
  const inputPrice = useRef("");
  const inputName = useRef("");
  const inputCost = useRef("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(props.modalServicoOpen);
  }, [props.modalServicoOpen]);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      price: parseFloat(inputPrice.current.value),
      name: inputName.current.value,
      estimatedDays: estimatedDays,
      estimatedMinutes: estimatedMinutes,
      cost: parseFloat(inputCost.current.value),
    };

    createServico(data)
      .then((response) => {
        props.listServices();
        close();
        setFinalValue(0);
        toast.success("Serviço cadastrado");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alert({ error });
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel adicionar serviço");
      });
  }

  function calculateFinalValue() {
    let cost = inputCost.current.value ?? 0;
    let price = inputPrice.current.value ?? 0;

    try {
      setFinalValue(parseFloat(price - cost));
    } catch (error) {
      console.log("error", error);
    }
  }

  function close() {
    props.setModalServicoOpen(false);
  }
  
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={close}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={close}>
          Cadastro de novo serviço
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="serviceName" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputName}
                  type="text"
                  id="serviceName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Preço
                </label>
                <input
                  ref={inputPrice}
                  id="preco"
                  type="number"
                  step={0.01}
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col-lg-12">
                <label htmlFor="tempoEstimado" className="form-label bold">
                  Previsão de entrega
                </label>
                <div className="row mt-2">
                  <div className="col-lg-2">
                    <label htmlFor="tempoEstimado" className="form-label">
                      Dias
                    </label>
                    <input
                      className="form-control"
                      defaultValue={estimatedDays}
                      onChange={(e) => setEstimatedDays(e.target.value)}
                      type="number"
                      max="180"
                      minLength="1"
                    />
                  </div>
                  <div className="col-lg-2">
                    <label htmlFor="tempoEstimado" className="form-label">
                      Minutos
                    </label>
                    <input
                      className="form-control"
                      defaultValue={estimatedMinutes}
                      onChange={(e) => setEstimatedMinutes(e.target.value)}
                      type="number"
                      minLength="1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Adicionar
                </button>
                <button
                  type="button"
                  onClick={close}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default CadastrarServicoModal;
