import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function connect() {
  const serviceControllUrl = `${API_URL}/whatsapp`;
  const response = await axiosInstance.post(serviceControllUrl, {}, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function disconnect(delay = true, status) {
  const serviceControllUrl = `${API_URL}/whatsapp`;
  const response = await axiosInstance.delete(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function status(delay = true, status) {
  const serviceControllUrl = `${API_URL}/whatsapp`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}