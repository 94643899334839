import React, { useEffect, useState } from "react";

export const PaginationBootstrap = (props) => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const pagesUpdate = [];
    if (props.pageInfo && props.pageInfo.totalPages) {
      const maxPages = Math.min(10, props.pageInfo.totalPages);
      for (let i = 1; i <= maxPages; i++) {
        pagesUpdate.push(i);
      }
      setPages(pagesUpdate);
    }
  }, [props]);

  function isActive(page) {
    if (page == props.pageInfo.currentPage + 1)
      return "page-link active cursor bg-secondary text-light";

    return "page-link cursor bg-dark text-light";
  }

  function previousPage(e) {
    e.preventDefault();

    const updatedPages = [...pages];

    if (updatedPages[0] > 1) {
      updatedPages.pop();

      const firstPage = updatedPages[0] - 1;
      updatedPages.unshift(firstPage);

      setPages(updatedPages);
    }
  }

  function nextPage(e) {
    e.preventDefault();

    const updatedPages = [...pages];

    if (updatedPages[updatedPages.length - 1] < props.pageInfo.totalPages) {
      updatedPages.shift();

      const lastPage = updatedPages[updatedPages.length - 1] + 1;
      updatedPages.push(lastPage);

      setPages(updatedPages);
    }
  }

  return (
    <div className="d-flex mt-lg-0 mt-3 justify-content-center align-items-center">
      <nav aria-label="Page navigation example bg-dark">
        <ul className="pagination">
          <li className="page-item">
            <a
              className="page-link enabled bg-dark text-light"
              href="#"
              aria-label="Previous"
              onClick={previousPage}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>

          {pages.map((page) => (
            <li key={page} className="page-item">
              <a
                className={isActive(page)}
                onClick={() => props.goToPage(page - 1)}
              >
                {page}
              </a>
            </li>
          ))}

          <li className="page-item">
            <a
              className="page-link enabled bg-dark text-light"
              href=""
              aria-label="Next"
              onClick={nextPage}
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          <div className="mx-2 align-self-center">
            Registros <strong>{props.pageInfo.totalElements}</strong>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationBootstrap;
