import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const solidRGBColors = [
  "rgb(65, 105, 225)",   // Azul Royal
  "rgb(51, 51, 51)",     // Cinza Escuro
  "rgb(0, 0, 0)",        // Preto
  "rgb(0, 31, 63)",      // Azul Marinho
  "rgb(128, 128, 128)",  // Cinza Médio
  "rgb(30, 30, 30)",     // Preto Profundo
  "rgb(65, 105, 225)",  // Azul Celeste
];

export const options = {
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  },
  maintainAspectRatio: false,

  indexAxis: "x",
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Faturamento",
    },
  },
};

export default function Chart(props) {
  const dados = {
    datasets: props.sales
      ? props.sales.map((det) => ({
          label: det[2],
          data: { [det[2]]: det[1] },
          backgroundColor: solidRGBColors[0],
          borderColor: "rgb(128, 128, 128)",
        }))
      : [],
  };
  
  return <Bar options={options} data={dados} />;
}
