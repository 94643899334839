import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useRef } from "react";
import { historyEmployee, updateUser } from "../../../services/UserService";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import currencyBRFormat from "../../../util/CurrencyUtils";

export const HistoricoServicosUsuario = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [roles, setRoles] = useState();
  const inputName = useRef("");
  const inputEmail = useRef("");
  const [filterDate, setFilterDate] = useState(new Date());
  const [employeeHistory, setEmployeeHistory] = useState();

  useEffect(() => {
    setIsOpen(props.openHistory);
    historyEmployee(props.employee.id, dayjs(filterDate).format("YYYY-MM-DD"))
      .then((response) => {
        setEmployeeHistory(response);
      })
      .catch((error) => console.error(error));
  }, [filterDate]);

  useEffect(() => {
    setIsOpen(props.openHistory);
  }, [props]);

  const toggle = () => {
    props.setOpenHistory(false);
  };

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      name: inputName.current.value,
      email: inputEmail.current.value,
      roles: roles
        ? roles.map((role) => role.key)
        : props.user.roles.map((role) => role.name),
    };

    updateUser(props.user.id, data)
      .then((response) => {
        props.listUsers(false);
        toast.success(response);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel atualizar usuário");
      });
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          <div className="d-lg-flex">
            <h5 className="modal-title" id="exampleModalLabel">
              Histórico do funcionário
            </h5>
            <div className="col-6 col-lg-3 mt-lg-0 mt-2 mx-lg-3">
              <ReactDatePicker
                showIcon
                icon="fa fa-plus"
                className=" form-control"
                dateFormat="dd/MM/yyyy"
                locale="pt-BR"
                selected={filterDate}
                onChange={(date) => setFilterDate(date)}
                maxDate={new Date()}
                todayButton="Hoje"
                required
              />
            </div>
          </div>
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div>
              {employeeHistory && employeeHistory.length > 0 ? (
                <>
                  <div>
                    <div>
                      <label>
                        <strong>Nome: </strong> {props.employee.name}
                      </label>
                    </div>
                    <div>
                      <label>
                        <strong>Comissão: </strong> {props.employee.comission}%
                      </label>
                    </div>
                  </div>
                  <hr />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <th>Serviços</th>
                        <th>Preço</th>
                        <th>Comissão</th>
                      </thead>
                      <tbody>
                        {employeeHistory.map((h) => (
                          <tr key={h.id}>
                            <td>{h.servicesNames}</td>
                            <td>{currencyBRFormat(h.servicesTotal)}</td>
                            <td>{currencyBRFormat(h.servicesComission)}</td>
                          </tr>
                        ))}
                        <tr>
                          <td />
                          <td />
                          <td>
                            {currencyBRFormat(
                              employeeHistory &&
                                employeeHistory
                                  .map((h) => h.servicesComission)
                                  .reduce(
                                    (a, b) => parseFloat(a) + parseFloat(b),
                                    0
                                  )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div>
                  Sem histórico de serviços realizados pelo{" "}
                  {props.employee.name} na data{" "}
                  {dayjs(filterDate).format("DD/MM/YYYY")}.
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark d-none">
              Exportar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default HistoricoServicosUsuario;
