export default function currencyBRFormat(value) {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return formatter.format(value);
}

export function convertStringToFloat(str) {
  const numericString = str.replace(/[^\d,]/g, "");
  const formattedNumber = parseFloat(numericString.replace(",", ".")); // Substitui vírgula por ponto e converte para float
  return formattedNumber.toFixed(2);
}
