import React, { useState } from "react";
import { useRef } from "react";
import { createExpense } from "../../../services/ExpenseService";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");
const dayjs = require("dayjs");

export const AdicionarDespesaModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputDescription = useRef("");
  const inputValue = useRef("");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const expensesTypes = [
    { name: "Variável", value: "VARIABLE" },
    { name: "Fixa", value: "FIXED" },
  ];
  const [selectedTypeExpense, setSelectedTypeExpense] = useState({});
  const toggle = () => setIsOpen(!isOpen);
  const [paid, setPaid] = useState(false);
  const [notifyDueDate, setNotifyDueDate] = useState(true);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const body = {
      value: parseFloat(inputValue.current.value),
      description: inputDescription.current.value,
      type:
        selectedTypeExpense.value != null
          ? selectedTypeExpense.value
          : expensesTypes[0].value,
      createdAt: dayjs(createdAt).format("YYYY-MM-DD"),
      paid,
      notifyDueDate,
    };

    createNewExpense(body);
  }

  function createNewExpense(body) {
    createExpense(body)
      .then((response) => {
        setLoading(false);
        setIsOpen(false);
        toast.success(response);
        props.listExpenses(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }

  const formatSelectLabels = (type) => <span>{type.name}</span>;

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i>
        Nova despesa
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de nova despesa</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-3 mb-3">
                <label className="form-label bold">Tipo</label>
                <Select
                  onChange={(data) => setSelectedTypeExpense(data)}
                  id="listaTipos"
                  name="tipos"
                  classNamePrefix="select"
                  defaultValue={expensesTypes[0]}
                  options={expensesTypes}
                  className="basic-multi-select"
                  formatOptionLabel={formatSelectLabels}
                  placeholder="Tipo despesa"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3 col-12">
                <label className="form-label bold">Descrição</label>
                <input
                  ref={inputDescription}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-3 col-6">
                <label className="form-label bold">Valor R$</label>
                <input
                  ref={inputValue}
                  className="form-control"
                  type="number"
                  step={0.01}
                  required
                />
              </div>
              <div className="col-lg-3 col-6">
                <label className="form-label bold">Data vencimento</label>
                <div>
                  <DatePicker
                    className="custom-datepicker form-control"
                    dateFormat="dd/MM/yyyy"
                    locale="pt-BR"
                    selected={createdAt}
                    onChange={(date) => setCreatedAt(date)}
                    todayButton="Hoje"
                    required
                  />
                </div>
              </div>

              <div
                className="col-lg-4 mt-3 mt-lg-0"
                title="Marque sim se deseja receber mensagem 1 dia antes da despesa vencer e tambem no dia de vencimento"
              >
                <label className="form-label bold">Lembrete vencimento</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={notifyDueDate}
                      defaultChecked={notifyDueDate}
                      onChange={() => setNotifyDueDate(!notifyDueDate)}
                    />
                    <label className="form-check-label">
                      {notifyDueDate ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 mt-lg-0 mt-3">
                <label className="form-label bold">Está paga?</label>

                <div className="align-self-center">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={paid}
                      defaultChecked={paid}
                      onChange={() => setPaid(!paid)}
                    />
                    <label className="form-check-label">
                      {paid ? "Sim" : "Não"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Adicionar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default AdicionarDespesaModal;
