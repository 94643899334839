import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { sendServiceQuotation } from "../../../../services/ReportService";
import Select from "react-select";
import { findByLicensePlate } from "../../../../services/CarService";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import removerSpecialChars from "../../../../util/StringUtils";
import { findCustomerByPhone } from "../../../../services/CustomerService";
import Spinner from "../../../../components/Spinner";
import carBrands from "../../../../consts/CarBrands";
import CadastrarServicoModal from "./CadastrarServicoModal";

export const OrcamentoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [searchingCar, setSearchingCar] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const inputLicensePlate = useRef("");
  const inputVehicleColor = useRef("");
  const inputVehicleModel = useRef("");
  const inputCustomerName = useRef("");
  const inputCustomerPhone = useRef("");
  const inputComments = useRef("");
  const inputAddress = useRef("");
  const [vehicleBrand, setVehicleBrand] = useState();
  const [brandSelectDisabled, setBrandSelectDisabled] = useState(false);
  const [modalServicoOpen, setModalServicoOpen] = useState(false);
  const inputQtd = useRef("");
  const inputItemName = useRef("");
  const inputItemValue = useRef("");
  const [selectedServies, setSelectedServies] = useState([]);

  useEffect(() => {}, [isOpen]);

  function findByPhone() {
    findCustomerByPhone(
      "55" + removerSpecialChars(inputCustomerPhone.current.value)
    )
      .then((response) => {
        if (response) {
          fillInputsCustomer(response);
        } else {
          clearInputsCustomer();
        }
      })
      .catch((error) => {
        clearInputsCustomer();
      });
  }

  function fillInputsCustomer(response) {
    inputCustomerName.current.value = response.name;
    inputAddress.current.value = response.address;
    inputCustomerName.current.disabled = true;
    inputAddress.current.disabled = true;
    //inputCustomerPhone.current.disabled = true;
  }

  function clearInputsCustomer() {
    inputCustomerPhone.current.disabled = false;
    inputCustomerName.current.disabled = false;
    inputAddress.current.disabled = false;
    inputCustomerName.current.value = "";
    inputAddress.current.value = "";
    //inputCustomerPhone.current.value = "";
  }

  function fillInputsVehicle(response, licensePlate) {
    setSearchingCar(false);
    setVehicleBrand({
      key: response.brand,
      label: response.brand.replace("_", " "),
      value: response.brand.replace("_", " "),
    });

    inputLicensePlate.current.value = licensePlate;
    inputVehicleColor.current.value = response.color;
    inputVehicleModel.current.value = response.model;

    setBrandSelectDisabled(true);
    inputVehicleColor.current.disabled = true;
    inputVehicleModel.current.disabled = true;

    const customer = response.customer;
    if (customer) {
      //fillInputsCustomer(customer);
    }
  }

  function clearInputsVehicle() {
    setSearchingCar(false);
    inputVehicleColor.current.disabled = false;
    inputVehicleModel.current.disabled = false;

    setVehicleBrand(null);
    setBrandSelectDisabled(false);
  }

  function addItem() {
    if (inputItemName.current.value && inputItemValue.current.value) {
      const item = {
        value: inputItemName.current.value,
        label: inputItemName.current.value,
        key: inputItemValue.current.value,
      };
      setSelectedServies((prevLista) => [...prevLista, item]);
      inputItemName.current.value = null;
      inputItemValue.current.value = null;
      inputQtd.current.value = 1;
    } else {
      toast.warning("Nome e valor dos Serviços/Peças devem ser informados");
    }
  }

  function handleInputLicensePlate(e) {
    e.preventDefault();
    setSearchingCar(true);
    if (e.target.value) {
      findByLicensePlate(e.target.value)
        .then((response) => {
          if (response) {
            fillInputsVehicle(response, e.target.value);
          } else {
            inputLicensePlate.current.value = e.target.value;
            clearInputsVehicle();
          }
        })
        .catch((error) => {
          setSearchingCar(false);
          setVehicleBrand(null);
          setBrandSelectDisabled(false);
        });
    } else {
      inputVehicleColor.current.value = "";
      inputVehicleModel.current.value = "";
      clearInputsVehicle();
    }
  }

  const formatServicesLabels = (service) => (
    <>
      <span>{service.label}</span>
      <span> - R$ {service.key}</span>
    </>
  );

  function handleSubmit(e) {
    setWaiting(true);
    e.preventDefault();

    const data = {
      vehicle: {
        licensePlate: inputLicensePlate.current.value,
        brand: vehicleBrand.key,
        color: inputVehicleColor.current.value,
        model: inputVehicleModel.current.value,
      },
      employeeName: localStorage.getItem("userName"),
      servicesAndParts: selectedServices.map((service) => ({
        id: service.value,
        name: service.label,
        value: parseFloat(service.key),
      })),
      customer: {
        name: inputCustomerName.current.value,
        phone: "55" + removerSpecialChars(inputCustomerPhone.current.value),
        address: inputAddress.current.value,
        notify: true,
      },
      comments: inputComments.current.value,
    };

    sendServiceQuotation(data)
      .then((response) => {
        toast.success(response);
        setWaiting(false);
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) toast.error(error.response.data);
        else
          toast.error(
            "Não foi possivel enviar orçamento, verifique o telefone do cliente e tente novamente"
          );
        setWaiting(false);
      });
  }

  function calculateTotalSelectServices() {
    return currencyBRFormat(
      selectedServices
        .map((service) => service.key)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    );
  }

  return (
    <div>
      <CadastrarServicoModal
        setModalServicoOpen={setModalServicoOpen}
        modalServicoOpen={modalServicoOpen}
        listServices={props.listServices}
      />
      <button className="btn" type="button" onClick={() => setIsOpen(true)}>
        <i className="fa fa-calculator me-2"></i>Orçamento
      </button>

      <Modal isOpen={isOpen} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Envio de orçamento</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <label className="form-label bold">Dados do cliente</label>

              <div className="col-lg-6 mb-3">
                <label className="form-label">Telefone</label>
                <ReactInputMask
                  ref={inputCustomerPhone}
                  autoComplete="off"
                  className="form-control"
                  mask="(99) 99999-9999"
                  onBlur={findByPhone}
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">Nome</label>
                <input
                  autoComplete="off"
                  ref={inputCustomerName}
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-12 mb-2">
                <label className="form-label">Endereço</label>
                <input
                  autoComplete="off"
                  ref={inputAddress}
                  className="form-control"
                  placeholder="Opcional"
                />
              </div>
            </div>

            <div className="row">
              <label className="form-label bold">Dados do veículo</label>
              {searchingCar ? (
                <Spinner />
              ) : (
                <>
                  <div className="col-lg-2 mb-3">
                    <label className="form-label">Placa</label>
                    <input
                      onBlur={handleInputLicensePlate}
                      ref={inputLicensePlate}
                      type="text"
                      id="placaCarro"
                      maxLength={7}
                      minLength={7}
                      className="form-control text-uppercase"
                      required
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label className="form-label">Marca</label>

                    <Select
                      id="selectBrand"
                      name="brands"
                      classNamePrefix="select"
                      onChange={(brand) => setVehicleBrand(brand)}
                      value={vehicleBrand}
                      options={carBrands.map((brand, index) => ({
                        value: brand.replace("_", " "),
                        key: brand,
                        label: brand.replace("_", " "),
                      }))}
                      className="basic-multi-select"
                      placeholder=""
                      isDisabled={brandSelectDisabled}
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label className="form-label">Modelo</label>
                    <input
                      ref={inputVehicleModel}
                      id="modeloCarro"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label className="form-label">Cor</label>
                    <input
                      id="corCarro"
                      ref={inputVehicleColor}
                      className="form-control"
                      aria-label="Default select example"
                      placeholder="Opcional"
                    />
                  </div>
                </>
              )}
            </div>

            <div className="d-none row mt-2 mb-3">
              <div className="col-lg-12">
                <label htmlFor="listaServicos" className="form-label bold">
                  Serviços/Peças
                </label>

                <div className="row">
                  <div className="col-lg-2">
                    <input
                      ref={inputQtd}
                      type="number"
                      defaultValue={1}
                      className="form-control"
                      placeholder="Qtd"
                      min={1}
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-lg-0 mt-3">
                    <input
                      ref={inputItemName}
                      className="form-control"
                      placeholder="Descrição serviços ou peças"
                    />
                  </div>
                  <div className="col-lg-2 mt-lg-0 mt-3">
                    <input
                      ref={inputItemValue}
                      type="number"
                      className="form-control"
                      placeholder="Preço"
                      min={1}
                    />
                  </div>

                  <div className="col-lg-2 mt-lg-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-dark"
                      onClick={addItem}
                    >
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 mb-3">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Serviços a fazer
                  </label>
                  <button
                    onClick={() => setModalServicoOpen(true)}
                    type="button"
                    className="btn btn-dark mx-lg-3 mx-2"
                    style={{ fontSize: "0.8em" }}
                    title="Caso não encontre o serviço na lista de serviços abaixo este botão para cadastrar um novo serviço rapidamente"
                  >
                    Cadastrar serviço
                  </button>
                </div>
                <div className="mt-2">
                  <Select
                    onChange={(data) => setSelectedServices(data)}
                    id="listaServicos"
                    isMulti
                    name="services"
                    classNamePrefix="select"
                    options={props.services}
                    value={selectedServices}
                    className="basic-multi-select"
                    formatOptionLabel={formatServicesLabels}
                    placeholder="Escolha os serviços a serem realizados"
                    isClearable
                    required
                  />

                  <div className="text-end mt-2 bold">
                    Total: {calculateTotalSelectServices()}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Observações
                  </label>
                </div>

                <div>
                  <textarea
                    ref={inputComments}
                    className="form-control"
                    placeholder="Opcional"
                  />
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!waiting ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Enviar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default OrcamentoModal;
