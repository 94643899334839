import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { paySale, deleteSaleById } from "../../../../services/SaleService";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { maskPhone } from "../../../../util/StringUtils";
import MultiPayment from "../../../../components/Multipayment";
import paymentMethodBR from "../../../../util/paymentMethodUtils";
import { getLoyaltyCardCompleted } from "../../../../services/LoyaltyService";
const dayjs = require("dayjs");

const OPENED = "OPENED";

export const ConcluirVendaModal = (props) => {
  const [sale, setSale] = useState(props.sale);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const inputPaymentMethod = useRef("");
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [discount, setDiscount] = useState(0.0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(0.0);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [discountTypePercentual, setDiscountTypePercentual] = useState(true);
  const [adtionalValue, setadtionalValue] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [multiPayment, setMultiPayment] = useState(false);
  const [loyaltyCardInfo, setLoyaltyCardInfo] = useState();

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (sale.customer) {
      setSelectedCustomer({
        value: sale.customer.id,
        label: sale.customer.name,
        key: maskPhone(sale.customer.phone.substring(2)),
      });
      setDisabledSelect(true);
      if (isOpen) {
        getLoyaltyCardCompleted(sale.customer.id).then((response) => {
          if (response) {
            setLoyaltyCardInfo(response);
          }
        });
      }
    }

    setLoyaltyCardInfo();
    setDiscount(0.0);
    setadtionalValue(0);
    setTotalWithDiscount(sale.total);
    setPriceWithDiscount(0.0);
    setDiscountTypePercentual(true);
  }, [isOpen]);

  useEffect(() => {
    if (loyaltyCardInfo && loyaltyCardInfo.completed) setDiscount(100);
  }, [loyaltyCardInfo]);

  const formatCustomersLabels = (customer) => (
    <>
      <span>{customer.label}</span>
      <span> - {customer.key}</span>
    </>
  );

  function deleteSale() {
    const execute = window.confirm(
      `Você tem certeza de que deseja excluir esta venda registrada em ${dayjs(
        sale.createdAt
      ).format("DD/MM/YYYY")}?`
    );

    if (execute) {
      deleteSaleById(sale.id)
        .then((response) => {
          props.listSales(OPENED);
          toast.success(response);
        })
        .catch((error) => {
          toast.error("Não foi possível exluir essaa venda");
        });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    try {
      validatePaidValue();
      const execute = window.confirm(
        `Revise os detalhes da venda antes de concluir!
        \nSubtotal: ${currencyBRFormat(
          sale.total
        )}\nValor Adicional: ${currencyBRFormat(
          adtionalValue
        )}\nDesconto: ${currencyBRFormat(
          priceWithDiscount
        )}\nTotal: ${currencyBRFormat(totalWithDiscount)}
        \nFormas de pagamento${
          inputPaymentMethod.current
            ? "\n" +
              paymentMethodBR(inputPaymentMethod.current.value) +
              ": " +
              currencyBRFormat(totalWithDiscount)
            : paymentList
                .map((p) => "\n" + p.name + ": " + currencyBRFormat(p.value))
                .join("")
        }`
      );

      if (execute) {
        setLoading(true);
        const data = {
          paymentList: treatMultiPayment(),
          customerId: selectedCustomer ? selectedCustomer.value : null,
          discount: priceWithDiscount,
          aditionalValue: adtionalValue ? adtionalValue : 0,
          usingLoyaltyCard: loyaltyCardInfo ? loyaltyCardInfo.completed : false,
        };

        paySale(props.sale.id, data)
          .then((response) => {
            setSelectedCustomer();
            setDisabledSelect(false);
            props.listSales(OPENED);
            setIsOpen(false);
            setLoading(false);
            toast.success("Venda concluída");
          })
          .catch((error) => {
            toast.error("Não foi possível concluir a venda");
            console.error(error)
            setLoading(false);
          });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }

  useEffect(() => {
    setPriceWithDiscount(
      discountTypePercentual ? calculatePercentDiscount() : discount
    );
    calculateTotal();
  }, [discountTypePercentual, discount, adtionalValue]);

  function calculatePercentDiscount() {
    return (
      (parseFloat(discount) / 100) *
      (parseFloat(sale.total) + parseFloat(adtionalValue))
    ).toFixed(2);
  }

  function calculateTotal() {
    setTotalWithDiscount(
      parseFloat(sale.total) +
        parseFloat(adtionalValue) -
        (discountTypePercentual ? calculatePercentDiscount() : discount)
    );
  }

  function treatMultiPayment() {
    if (inputPaymentMethod.current) {
      return [
        { key: inputPaymentMethod.current.value, value: totalWithDiscount },
      ];
    }

    return paymentList;
  }

  function validatePaidValue() {
    const paidValue = treatMultiPayment()
      .map((p) => p.value)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    if (paidValue < totalWithDiscount) {
      throw new Error("Valor das formas de pagamento insufiente");
    }
  }

  function addPayment(paymentMethod, paymentValue) {
    const payment = paymentList.find(
      (p) => p.key == paymentMethod.current.value
    );

    if (paymentValue && paymentValue > 0) {
      const paidValue = paymentList
        .map((p) => p.value)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

      if (paidValue + parseFloat(paymentValue) > totalWithDiscount) {
        toast.warning(
          `Não pode adicionar pagamento, resta pagar ${currencyBRFormat(
            totalWithDiscount - paidValue
          )}`
        );
        return;
      }
      if (payment) {
        toast.warning(
          `Forma de pagamento ${paymentMethodBR(
            paymentMethod.current.value
          )} já adicionada, remova ou adicione uma diferente`
        );
        return;
      }
      const item = {
        key: paymentMethod.current.value,
        name: paymentMethodBR(paymentMethod.current.value),
        value: paymentValue,
      };
      setPaymentList((prev) => [...prev, item]);
    } else {
      toast.warning("Valor da forma de pagamento deve ser maior que zero");
    }
  }

  return (
    <div>
      <div className="d-flex">
        <button
          onClick={() => setIsOpen(true)}
          type="button"
          className="btn btn-dark"
        >
          Receber
        </button>

        <button
          onClick={() => deleteSale()}
          type="button"
          className="btn btn-danger mx-3"
        >
          <i className="fa fa-trash"></i>
        </button>
      </div>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Concluir venda</ModalHeader>
        {loyaltyCardInfo && loyaltyCardInfo.completed && (
          <div className="alert alert-primary bold" role="alert">
            Este cliente completou o Cartão Fidelidade.
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-6 mb-lg-0 mb-3">
                <div className="mb-2">
                  <label htmlFor="metodoPagamento" className="form-label bold">
                    Formas de pagamento
                  </label>

                  <div className="align-self-center">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="switchPaymentMethod"
                        checked={multiPayment}
                        onChange={() => setMultiPayment(!multiPayment)}
                      />
                      <label
                        className="form-check-label"
                        for="switchPaymentMethod"
                      >
                        {multiPayment ? "Várias" : "Uma"}
                      </label>
                    </div>
                  </div>
                </div>

                {multiPayment ? (
                  <MultiPayment
                    inputPaymentMethod={inputPaymentMethod}
                    paymentList={paymentList}
                    addPayment={addPayment}
                    setPaymentList={setPaymentList}
                    totalWithDiscount={totalWithDiscount}
                  />
                ) : (
                  <select
                    id="paymentMethod"
                    ref={inputPaymentMethod}
                    className="form-select"
                    aria-label="Default select example"
                    required
                  >
                    <option value="MONEY">Dinheiro</option>
                    <option value="CREDIT">Crédito à vista</option>
                    <option value="INSTALLMENT_CREDIT">
                      Crédito parcelado
                    </option>
                    <option value="DEBIT">Débito</option>
                    <option value="PIX">Pix</option>
                    <option value="BANK_SLIP">Boleto</option>
                  </select>
                )}
              </div>

              <div className="col-lg-6">
                <label htmlFor="customerSelect" className="form-label bold">
                  Cliente
                </label>
                <Select
                  isDisabled={disabledSelect}
                  onChange={(customer) => setSelectedCustomer(customer)}
                  id="customerSelect"
                  name="customers"
                  classNamePrefix="select"
                  options={props.customers}
                  className="basic-multi-select"
                  value={selectedCustomer}
                  formatOptionLabel={formatCustomersLabels}
                  placeholder=""
                />

                {loyaltyCardInfo && (
                  <div className="mt-3">
                    <label>
                      <b className="bold">Cartão Fidelidade:</b>{" "}
                      {loyaltyCardInfo.servicesCount}
                    </label>
                    <div className="progress mt-" role="progressbar">
                      <div
                        className="progress-bar"
                        style={{ width: loyaltyCardInfo.percentProgress }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col col-lg-12">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Qtd</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Preço</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.sale.servicesAndParts.map((obj) => (
                      <tr key={obj.name}>
                        <td>{obj.qtd}</td>
                        <td>{obj.name}</td>
                        <td>{currencyBRFormat(obj.price)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="row mt-2">
                <div className="col-lg-3">
                  <label className="form-label bold">Valor Adicional</label>
                  <input
                    className="form-control"
                    type="number"
                    value={adtionalValue}
                    onChange={(e) =>
                      setadtionalValue(e.target.value > 0 ? e.target.value : 0)
                    }
                    defaultValue={adtionalValue}
                    min={0}
                    required
                  />
                </div>

                <div className="col-lg-3 mt-lg-0 mt-2">
                  <label htmlFor="desconto" className="form-label bold me-2">
                    Tipo desconto
                  </label>
                  <div className="form-check form-switch mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="switchDiscountType"
                      checked={discountTypePercentual}
                      onChange={() =>
                        setDiscountTypePercentual(!discountTypePercentual)
                      }
                    />
                    <label
                      className="form-check-label"
                      for="switchDiscountType"
                    >
                      {discountTypePercentual ? "Percentual" : "Valor"}
                    </label>
                  </div>
                </div>

                <div className="col-12 col-lg-3 mt-lg-0 mt-2">
                  <label htmlFor="desconto" className="form-label bold me-2">
                    Desconto em {discountTypePercentual ? "%" : "R$"}
                  </label>
                  <input
                    className="form-control"
                    value={discount}
                    disabled={loyaltyCardInfo && loyaltyCardInfo.completed}
                    onChange={(e) =>
                      setDiscount(e.target.value > 0 ? e.target.value : 0.0)
                    }
                    id="desconto"
                    type="number"
                    min={0}
                    max={discountTypePercentual ? 100 : sale.total}
                  />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-lg-2 mt-lg-0 mt-2">
                  <label htmlFor="customerSelect" className="form-label bold">
                    Subtotal
                  </label>
                  <p className="mt-lg-1"> {currencyBRFormat(sale.total)}</p>
                </div>
                <div className="col-lg-3">
                  <label htmlFor="customerSelect" className="form-label bold">
                    Valor Adicional
                  </label>
                  <p className="mt-lg-1">{currencyBRFormat(adtionalValue)}</p>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="customerSelect" className="form-label bold">
                    Desconto
                  </label>
                  <p className="mt-lg-1">
                    {currencyBRFormat(priceWithDiscount)}
                  </p>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="customerSelect" className="form-label bold">
                    Total
                  </label>
                  <p className="mt-lg-1">
                    {currencyBRFormat(totalWithDiscount)}
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark mx-3">
                  Concluir
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default ConcluirVendaModal;
