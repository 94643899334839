import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { useRef } from "react";
import { updateUser } from "../../../services/UserService";
import { toast } from "react-toastify";
import Select from "react-select";
import ROLES from "./roles";

export const EditarUsuarioModal = (props) => {
  const [isOpen, setIsOpen] = useState();
  const [roles, setRoles] = useState();
  const inputName = useRef("");
  const inputEmail = useRef("");
  const inputNewPassword = useRef("");
  const inputMatchNewPassword = useRef("");
  const [passMatches, setPassMatches] = useState(true);
  const [comission, setComission] = useState(props.user.comission ?? 0);
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    e.preventDefault();
    if (!passwordsMatches()) {
      alert(
        "As senhas não conferem, tente novamente ou deixe em branco para atualizar os dados do funcionário."
      );
      return;
    }

    const data = {
      name: inputName.current.value,
      email: inputEmail.current.value,
      roles: roles ? [roles.key] : [props.user.roles[0].name],
      password: inputNewPassword.current.value,
      comission,
    };

    updateUser(props.user.id, data)
      .then((response) => {
        props.listUsers(false);
        toast.success(response);
        setIsOpen(false);
      })
      .catch((error) => {
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel atualizar usuário");
      });
  }

  function passwordsMatches() {
    if (inputNewPassword.current.value === inputMatchNewPassword.current.value)
      return true;
    else setPassMatches(false);
  }

  const formatRoleLabels = (role) => (
    <>
      <span>{role.value}</span>
    </>
  );

  return (
    <>
      <div>
        <button className="btn btn-dark" onClick={() => setIsOpen(true)}>
          Editar
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados do funcionário</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-6 mb-3">
                <label htmlFor="customerName" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputName}
                  defaultValue={props.user.name}
                  type="text"
                  id="customerName"
                  className="form-control"
                  required
                />
              </div>
              <div className="col col-12 col-sm-6 mb-3">
                <label htmlFor="userEmail" className="form-label bold">
                  E-mail
                </label>
                <input
                  ref={inputEmail}
                  defaultValue={props.user.email}
                  type="email"
                  id="userEmail"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-6">
                <label htmlFor="userRoles" className="form-label bold">
                  Funções
                </label>
                <Select
                  id="listaServicos"
                  onChange={setRoles}
                  isClearable={false}
                  name="roles"
                  classNamePrefix="select"
                  options={ROLES}
                  defaultValue={props.user.roles.map((role) => ({
                    key: role.name,
                    value: role.value,
                  }))}
                  formatOptionLabel={formatRoleLabels}
                  className="basic-multi-select"
                  placeholder="Função do funcionário"
                  required
                />
              </div>

              <div className="col-lg-6 mt-lg-0 mt-3">
                <label htmlFor="userRoles" className="form-label bold">
                  Comissão %
                </label>
                <input
                  onChange={(e) => setComission(e.target.value)}
                  value={comission}
                  className="form-control"
                  type="number"
                  max={100}
                  min={0}
                />
              </div>
              {localStorage.getItem("userId") == props.user.id && (
                <div className="row mt-3">
                  <label htmlFor="userRoles" className="form-label bold">
                    Alterar Senha
                  </label>

                  <div className="col-lg-6">
                    <input
                      autoComplete="new-password"
                      ref={inputNewPassword}
                      className="form-control"
                      type="password"
                      placeholder="Nova senha"
                    />
                  </div>

                  <div className="col-lg-6 mt-lg-0 mt-3">
                    <input
                      ref={inputMatchNewPassword}
                      className={`form-control ${
                        passMatches ? "" : "is-invalid"
                      }`}
                      placeholder="Confirme a nova senha"
                      type="password"
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Salvar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default EditarUsuarioModal;
