import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function createExpense(body) {
  const loginUrl = `${API_URL}/expenses`;
  const response = await axiosInstance.post(loginUrl, body, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function listAllExpensesByPage(page, filter) {
  const loginUrl = page ?`${API_URL}/expenses?page=${page}&size=100` : `${API_URL}/expenses?size=100`;
  const response = await axiosInstance.get(loginUrl, { headers: getAuthHeaders(), params: filter });
  return response.data;
}

export async function updateExpenseById(expenseId, body) {
  const loginUrl = `${API_URL}/expenses/${expenseId}`;
  const response = await axiosInstance.put(loginUrl, body, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deleteExpenseById(expenseId) {
  const loginUrl = `${API_URL}/expenses/${expenseId}`;
  const response = await axiosInstance.delete(loginUrl, { headers: getAuthHeaders() });
  return response.data;
}
