import React, { useState } from "react";
import { useRef } from "react";
import { createPromotion } from "../../../services/PromotionService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const PromocoesForm = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputText = useRef("");
  const inputDate = useRef("");
  const inputHour = useRef("");
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      text: inputText.current.value,
    };

    createPromotion(data)
      .then((response) => {
        props.listPromotions(false);
        setIsOpen(false);
        toast.success("Promoção adicionada");
      })
      .catch((error) => {
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel adicionar promoção");
      });
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i>
        Novo marketing
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de novo marketing</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="serviceName" className="form-label bold">
                  Conteúdo da mensagem
                </label>
                <textarea
                  ref={inputText}
                  type="text"
                  id="serviceName"
                  className="form-control"
                  rows={8}
                  required
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Adicionar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default PromocoesForm;
