import React, { useState, useEffect } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { listAllCompanies } from "../../../services/CompanyService";
import { CDBTable, CDBSpinner, CDBTableHeader, CDBTableBody } from "cdbreact";
import EmpresaForm from "./EmpresaForm";
import EditarEmpresa from "./EditarEmpresa";
import PaginationBootstrap from "../../../components/PaginationBootstrap";
import { maskCNPJ } from "../../../util/StringUtils";
const dayjs = require("dayjs");

export const Empresas = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    setIsLoading(true);
    listCompanies();
  }, []);

  function listCompanies(delay) {
    listAllCompanies(delay)
      .then((response) => {
        setCompanies(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function sortByCreatedAt() {
    const sortedCompanies = [...companies].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setCompanies(sortedCompanies);
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function renderLogo(logo) {
    if (logo) {
      const blob = new Blob([new Uint8Array(logo)], { type: "image/png" });

      // Crie uma URL temporária para o Blob
      return URL.createObjectURL(blob);
    }
    return "";
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>

      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3 w-100">
                <div className="d-flex justify-content-between">
                  <h3 className="font-weight-bold">Empresas</h3>
                </div>
                <div className="d-flex mt-3 mb-1">
                  <div>
                    <EmpresaForm listCompanies={listCompanies} />
                  </div>
                </div>
                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <CDBTable responsiveSm responsiveMd>
                      <CDBTableHeader>
                        <tr>
                          <th onClick={sortByCreatedAt}>Nome fantasia</th>
                          <th>CNPJ</th>
                          <th>Ativa</th>
                          <th>Teste</th>
                          <th>Vencimento</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {companies ? (
                          companies.map((company) => (
                            <tr key={company.id}>
                              <td>{company.fantasyName}</td>
                              <td>{maskCNPJ(company.documentNumber ?? "")}</td>
                              <td className="bold">
                                {company.active ? "Sim" : "Não"}
                              </td>
                              <td className="bold">
                                {company.testPeriod ? "Sim" : "Não"}
                              </td>
                              <td>
                                {dayjs(company.subscriptionEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <EditarEmpresa
                                    company={company}
                                    listCompanies={listCompanies}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>
                    <div>
                      {companies && companies.length} registros
                      <PaginationBootstrap
                        pageInfo={{ totalPages: 1, currentPage: 1 }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empresas;
