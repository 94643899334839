import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function doLogin(email, password) {
  const loginUrl = `${API_URL}/auth/signin`;
  const response = await axios.post(loginUrl, { email, password });
  return response.data;
}

export async function resetPassword(email) {
  const resetPasswordUrl = `${API_URL}/auth/reset-password`;
  const response = await axios.post(resetPasswordUrl, { email });
  return response.data;
}
