import React, { useState } from "react";
import { useRef } from "react";
import { updatePart } from "../../../services/PartService";
import { toast } from "react-toastify";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const EditarPecaModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [finalValue, setFinalValue] = useState(
    props.part.price - props.part.cost
  );
  const [costPrice, setCostPrice] = useState();
  const [price, setPrice] = useState();
  const inputPrice = useRef("");
  const inputName = useRef("");
  const inputCost = useRef("");

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      id: props.part.id,
      price: parseFloat(inputPrice.current.value),
      name: inputName.current.value,
      cost: parseFloat(inputCost.current.value),
    };

    updatePart(data)
      .then((response) => {
        toast.success(response);
        props.listParts();
        setIsOpen(false);
        setFinalValue(0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Não foi possivel editar essa peça");
        console.log(error);
      });
  }

  function calculateFinalValue() {
    let cost = inputCost.current.value ?? 0;
    let price = inputPrice.current.value ?? 0;

    console.log("cost ", inputCost.current.value);
    console.log("price ", inputPrice.current.value);

    try {
      setFinalValue(parseFloat(price) - parseFloat(cost));
      calculateNetValue();
    } catch (error) {
      console.log("error", error);
    }
    console.log("soma ", price - cost);

    console.log("finalValue ", finalValue);
  }

  function calculateNetValue() {
    return parseFloat(costPrice / price).toFixed() + "%";
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
      >
        Editar
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados da peça</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="partName" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputName}
                  defaultValue={props.part.name}
                  type="text"
                  id="partName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Preço
                </label>
                <input
                  defaultValue={props.part.price}
                  ref={inputPrice}
                  type="number"
                  step={0.01}
                  id="preco"
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="custo" className="form-label bold">
                  Custo
                </label>
                <input
                  defaultValue={props.part.cost}
                  ref={inputCost}
                  type="number"
                  step={0.01}
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col-lg-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Lucro
                </label>

                <p>{currencyBRFormat(finalValue)}</p>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Salvar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};
export default EditarPecaModal;
