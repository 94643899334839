import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { listAllParts, deletePart } from "../../../services/PartService";
import { CDBTable, CDBSpinner, CDBTableHeader, CDBTableBody } from "cdbreact";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { toast } from "react-toastify";
import AdicionarPeca from "./AdicionarPeca";
import EditarPecaModal from "./EditarPecaModal";

export const Pecas = () => {
  const [parts, setParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    setIsLoading(true);
    listParts();
  }, []);

  function listParts() {
    listAllParts()
      .then((response) => {
        setParts(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function remove(id) {
    const executeAction = window.confirm(
      `Confirma a exclusão irreversível desse serviço?`
    );

    if (executeAction) {
      deletePart(id)
        .then((response) => {
          listParts();
          toast.success(response);
        })
        .catch((error) => {
          toast.warning("Essa peça não pode ser excluida.");
        });
    }
  }
  function calculateLucro(price, cost) {
    return currencyBRFormat(parseFloat(price - cost));
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
           minHeight: "100vh",
           overflowX: "hidden"
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3>Peças</h3>
                </div>

                <div className="d-flex mt-3 mb-1">
                  <div>
                    <AdicionarPeca listParts={listParts} />
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <CDBTable responsiveSm responsiveMd small>
                      <CDBTableHeader>
                        <tr>
                          <th>Nome</th>
                          <th>Preço</th>
                          <th>Custo</th>
                          <th>Lucro</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {parts ? (
                          parts.map((part) => (
                            <tr key={part.id}>
                              <td>{part.name}</td>
                              <td>{currencyBRFormat(part.price)}</td>
                              <td>{currencyBRFormat(part.cost)}</td>
                              <td>{calculateLucro(part.price, part.cost)}</td>

                              <td>
                                <div className="d-flex">
                                  <EditarPecaModal part={part} listParts={listParts}/>
                                  <button
                                    onClick={() => remove(part.id)}
                                    className="btn btn-danger mx-3"
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>

                    {parts.length === 0 && <>Nenhuma peça cadastrada.</>}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pecas;
