import React, { useState } from "react";
import { useRef } from "react";
import { removeCustomerVehicle, updateCar } from "../../../services/CarService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import carBrands from "../../../consts/CarBrands";
import ReactSelect from "react-select";

export const EditarVeiculo = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [vehicleBrand, setVehicleBrand] = useState({
    key: props.vehicle.brand,
    label: props.vehicle.brand.replace("_", " "),
    value: props.vehicle.brand.replace("_", " "),
  });
  const inputLicensePlate = useRef("");
  const inputColor = useRef("");
  const inputModel = useRef("");
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const body = {
      id: props.vehicle.id,
      brand: vehicleBrand.key,
      color: inputColor.current.value,
      model: inputModel.current.value,
      licensePlate: inputLicensePlate.current.value,
    };

    updateVehicle(body);
  }

  function updateVehicle(body) {
    updateCar(body)
      .then((response) => {
        setLoading(false);
        setIsOpen(false);
        toast.success(response);
        props.listVehicles();
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && error.response.data)
          toast.warning(error.response.data);
        else toast.error("Não foi possivel atualizar este veículo");
      });
  }

  function removeCustomerFromVechicle(vehicle) {
    const action = window.confirm(
      "Tem certeza que deseja remover o vinculo do cliente deste veículo?"
    );

    if (action) {
      removeCustomerVehicle(vehicle.id)
        .then((response) => {
          toast.success("Removido vinculo cliente veiculo");
          setIsOpen(false);
          props.listVehicles();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.data)
            toast.warning(error.response.data);
          else toast.error("Não foi possivel remover vinculo deste veículo");
        });
    }
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
      >
        Editar
      </button>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          backdrop="static"
          size="lg"
        >
          <ModalHeader toggle={toggle}>Editar veículo</ModalHeader>

          <form onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-lg-2 mb-3">
                  <label className="form-label">Placa</label>
                  <input
                    defaultValue={props.vehicle.licensePlate}
                    ref={inputLicensePlate}
                    className="form-control text-uppercase"
                    minLength={7}
                    maxLength={7}
                    required
                  />
                </div>

                <div className="col-lg-4 mb-3">
                  <label className="form-label">Marca</label>
                  <ReactSelect
                    id="selectBrand"
                    name="brands"
                    classNamePrefix="select"
                    onChange={(brand) => setVehicleBrand(brand)}
                    value={vehicleBrand}
                    options={carBrands.map((brand, index) => ({
                      value: brand.replace("_", " "),
                      key: brand,
                      label: brand.replace("_", " "),
                    }))}
                    className="basic-multi-select"
                    placeholder=""
                    required
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label className="form-label">Modelo</label>
                  <input
                    defaultValue={props.vehicle.model}
                    ref={inputModel}
                    className="form-control"
                    required
                  />
                </div>

                <div className="col-lg-3 mb-3">
                  <label className="form-label">Cor</label>
                  <input
                    defaultValue={props.vehicle.color}
                    ref={inputColor}
                    className="form-control"
                    placeholder="Opcional"
                  />
                </div>

                <div className="row">
                  <div className="bold">Cliente</div>

                  <div>
                    {props.vehicle.customerName !== ""
                      ? props.vehicle.customerName
                      : "Não vinculado"}
                  </div>
                  {props.vehicle.customerName !== "" && (
                    <div className="mt-2">
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          removeCustomerFromVechicle(props.vehicle)
                        }
                        type="button"
                      >
                        Remover vinculo
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>

            <ModalFooter>
              {!loading ? (
                <>
                  <button className="btn btn-dark">Salvar</button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className="btn btn-danger"
                  >
                    Cancelar
                  </button>
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
            </ModalFooter>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default EditarVeiculo;
