import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { getEmployeesComissions } from "../../../services/UserService";
import dayjs from "dayjs";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { CDBTable, CDBTableBody, CDBTableHeader } from "cdbreact";

const RelatorioComissoesModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    props.setRelatorioComissionOpen(false);
  };
  const date = new Date();
  const defaultStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(date);
  const [employeesComissions, setEmployeesComissions] = useState();
  const [totalComissions, setTotalComissions] = useState();

  function listEmployeeComissions() {
    getEmployeesComissions(
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD")
    ).then((response) => {
      setEmployeesComissions(response.data);
      setTotalComissions(response.totalComissions);
    });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Relatório de comisões</ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-lg-3">
              <div className="mb-2">
                <label className="bold">Início</label>
              </div>

              <ReactDatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                locale="pt-BR"
                selected={startDate}
                todayButton="Hoje"
                onMonthChange={(date) => setStartDate(date)}
                onChange={(date) => setStartDate(date)}
              />
            </div>

            <div className="col-lg-3">
              <div className="mb-2 mt-lg-0 mt-3">
                <label className="bold">Fim</label>
              </div>

              <ReactDatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                locale="pt-BR"
                selected={endDate}
                todayButton="Hoje"
                onMonthChange={(date) => setEndDate(date)}
                onChange={(date) => setEndDate(date)}
              />
            </div>

            <div className="col-lg-3 mt-lg-0 mt-3 align-self-end">
              <button onClick={listEmployeeComissions} className="btn btn-dark">
                Gerar
              </button>
            </div>
          </div>

          {employeesComissions && (
            <div className="mt-4">
              <CDBTable responsiveSm responsiveMd>
                <CDBTableHeader>
                  <tr>
                    <th>Funcionário</th>
                    <th>Comissão Total</th>
                  </tr>
                </CDBTableHeader>
                <CDBTableBody>
                  {employeesComissions.map((ec) => (
                    <tr key={ec.id}>
                      <td>{ec.employeeName}</td>
                      <td>{currencyBRFormat(ec.employeeTotalComission)}</td>
                    </tr>
                  ))}

                  <tr>
                    <td></td>
                    <td>{currencyBRFormat(totalComissions)}</td>
                  </tr>
                </CDBTableBody>
              </CDBTable>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RelatorioComissoesModal;
