import axios from "axios";
const axiosInstance = axios.create();
let isAlertDisplayed = false;

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
   function (error) {
    console.log("axios interceptor: ", error);

    if (error.code === "ERR_NETWORK" && !isAlertDisplayed) {
      isAlertDisplayed = true; // Define a variável para true para indicar que o alerta foi exibido
        window.alert("Sua sessão expirou, faça login novamente.");
        localStorage.clear();
        window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
