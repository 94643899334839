export default function removerSpecialChars(str) {
    // Substitui todos os caracteres que não são letras nem números por uma string vazia
    return str.replace(/[^a-zA-Z0-9]/g, '');
  }

  export function maskPhone(phoneNumber) {
    return phoneNumber.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }

  export function maskCNPJ(cnpj) {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}