import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { updateServiceControll } from "../../../../services/ServiceControllService";
import { toast } from "react-toastify";
import Select from "react-select";
import { findByLicensePlate } from "../../../../services/CarService";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import carBrands from "../../../../consts/CarBrands";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Spinner from "../../../../components/Spinner";
import CadastrarServicoModal from "./CadastrarServicoModal";
import CadastrarClienteVeiculoModal from "./CadastrarClienteVeiculoModal";

export const EditarControleServico = (props) => {
  const [selectedServices, setSelectedServices] = useState(
    props.serviceControll.services.map((service) => ({
      value: service.id,
      label: service.name,
      key: service.price,
    }))
  );
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.customers.find((c) => c.value == props.serviceControll.customerId)
  );
  const [selectedEmployee, setSelectedEmployee] = useState(
    props.employees.find((e) => e.label == props.serviceControll.employeeName)
  );
  const inputLicensePlate = useRef("");
  const inputVehicleBrand = useRef("");
  const inputVehicleColor = useRef("");
  const inputVehicleModel = useRef("");
  const inputSelectCustomer = useRef();
  const inputSignValue = useRef("");
  const inputCheckList = useRef("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchingCar, setSearchingCar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalServicoOpen, setModalServicoOpen] = useState(false);
  const [modalCadastroCliente, setModalCadastroCliente] = useState(false);
  const [vehicleBrand, setVehicleBrand] = useState({
    label: carBrands.find((b) => b == props.serviceControll.car.brand),
  });

  useEffect(() => {
    setIsOpen(props.openEditModal);
  }, [props]);

  const toggle = () => {
    props.setOpenEditModal(false);
  };

  function handleInputLicensePlate(e) {
    e.preventDefault();
    setSearchingCar(true);
    if (e.target.value) {
      findByLicensePlate(e.target.value)
        .then((response) => {
          setSearchingCar(false);
          if (response) {
            inputLicensePlate.current.value = e.target.value;
            inputVehicleColor.current.value = response.color;
            inputVehicleModel.current.value = response.model;
            setVehicleBrand({
              key: response.brand,
              label: response.brand,
              value: response.brand,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setSearchingCar(false);
        });
    } else {
      setSearchingCar(false);
    }
  }

  const formatServicesLabels = (service) => (
    <>
      <span>{service.label}</span>
      <span> - R$ {service.key}</span>
    </>
  );

  const formatCustomersLabels = (customer) => (
    <>
      <span>{customer.key}</span>
      <span>{customer.label}</span>
    </>
  );

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      id: props.serviceControll.id,
      licensePlate: inputLicensePlate.current.value,
      vehicleBrand: inputVehicleBrand.current.value,
      vehicleColor: inputVehicleColor.current.value,
      vehicleModel: inputVehicleModel.current.value,
      servicesIds: selectedServices.map((service) => service.value),
      userId: localStorage.getItem("userId"),
      employeeId: selectedEmployee.value,
      customerId: selectedCustomer ? selectedCustomer.value : null,
      signValue: inputSignValue.current.value ?? 0,
      checklist: inputCheckList.current.value,
    };

    updateServiceControll(data)
      .then((response) => {
        props.listControllServices();
        toast.success("Controle de serviço atualizado");
        props.setOpenEditModal(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Não foi editar este controle de serviço");
      });
  }

  function calculateTotalSelectServices() {
    return currencyBRFormat(
      selectedServices
        ? selectedServices
            .map((service) => service.key)
            .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        : 0
    );
  }

  return (
    <div>
      <CadastrarServicoModal
        setModalServicoOpen={setModalServicoOpen}
        modalServicoOpen={modalServicoOpen}
        listServices={props.listServices}
      />

      <CadastrarClienteVeiculoModal
        setModalCadastroCliente={setModalCadastroCliente}
        open={modalCadastroCliente}
        listCustomers={props.listCustomers}
      />

      <Modal isOpen={isOpen} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Editar Controle de Serviço</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="customerSelect" className="form-label bold">
                  Cliente
                </label>
                <div className="mt-1">
                  <Select
                    ref={inputSelectCustomer}
                    onChange={(customer) => setSelectedCustomer(customer)}
                    id="customerSelect"
                    name="customers"
                    classNamePrefix="select"
                    value={selectedCustomer}
                    options={props.customers}
                    className="basic-multi-select"
                    placeholder="Escolha um cliente"
                    formatOptionLabel={formatCustomersLabels}
                    isClearable
                  />
                </div>
              </div>

              <div className="col-lg-4 mb-3 d-none">
                <label htmlFor="listaServicos" className="form-label bold">
                  Valor do sinal R$
                </label>
                <input
                  type="number"
                  defaultValue={0}
                  ref={inputSignValue}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row mt-4">
              <label htmlFor="customerSelect" className="form-label bold">
                Dados do veículo
              </label>
            </div>
            <div className="row mt-2">
              {searchingCar ? (
                <Spinner />
              ) : (
                <>
                  <div className="col-lg-2 mb-3">
                    <label htmlFor="placaCarro" className="form-label">
                      Placa
                    </label>
                    <input
                      onBlur={handleInputLicensePlate}
                      ref={inputLicensePlate}
                      defaultValue={props.serviceControll.car.licensePlate}
                      type="text"
                      id="placaCarro"
                      minLength={7}
                      maxLength={7}
                      className="form-control text-uppercase"
                      required
                    />
                  </div>
                  <div className="col-lg-4 mb-3">
                    <label htmlFor="marcaCarro" className="form-label">
                      Marca
                    </label>
                    <Select
                      id="selectBrand"
                      name="brands"
                      classNamePrefix="select"
                      onChange={(brand) => setVehicleBrand(brand)}
                      value={vehicleBrand}
                      options={carBrands.map((brand, index) => ({
                        value: brand.replace("_", " "),
                        key: brand,
                        label: brand.replace("_", " "),
                      }))}
                      className="basic-multi-select"
                      placeholder=""
                      isDisabled={true}
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label htmlFor="modeloCarro" className="form-label">
                      Modelo
                    </label>
                    <input
                      ref={inputVehicleModel}
                      defaultValue={props.serviceControll.car.model}
                      id="modeloCarro"
                      className="form-control"
                      disabled={true}
                      required
                    />
                  </div>
                  <div className="col-lg-3 mb-3">
                    <label htmlFor="corCarro" className="form-label">
                      Cor
                    </label>
                    <input
                      id="corCarro"
                      ref={inputVehicleColor}
                      disabled={true}
                      defaultValue={props.serviceControll.car.color}
                      className="form-control"
                      aria-label="Default select example"
                      required
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Checklist do veículo
                  </label>

                  <button
                    onClick={() =>
                      (inputCheckList.current.value =
                        "Veículo sem avarias ou detalhes")
                    }
                    className="btn btn-success mx-lg-3 mx-2"
                    type="button"
                    style={{ fontSize: "0.8em" }}
                    title="Texto padrão para checklist quando está tudo ok com o veículo, sem detalhes para mencionar"
                  >
                    Checklist sem avarias
                  </button>
                </div>

                <textarea
                  ref={inputCheckList}
                  defaultValue={props.serviceControll.checklist}
                  className="form-control mt-2"
                  placeholder="Informe avarias e/ou detalhes identificados no veículo como aranhões, amassados, pintura etc"
                  required
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <label htmlFor="listaServicos" className="form-label bold">
                  Funcionário
                </label>
                <Select
                  onChange={(data) => setSelectedEmployee(data)}
                  id="listaUsuarios"
                  name="services"
                  classNamePrefix="select"
                  value={selectedEmployee}
                  options={props.employees}
                  className="basic-multi-select"
                  placeholder="Escolha o funcionário responsável pelo serviço"
                  required
                />
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div>
                  <label htmlFor="listaServicos" className="form-label bold">
                    Serviços a fazer
                  </label>
                  <button
                    onClick={() => setModalServicoOpen(true)}
                    type="button"
                    className="btn btn-dark mx-lg-3 mx-2"
                    style={{ fontSize: "0.8em" }}
                    title="Caso não encontre o serviço na lista de serviços abaixo este botão para cadastrar um novo serviço rapidamente"
                  >
                    Cadastrar serviço
                  </button>
                </div>
                <div className="mt-2">
                  <Select
                    onChange={(data) => setSelectedServices(data)}
                    id="listaServicos"
                    isMulti
                    name="services"
                    classNamePrefix="select"
                    options={props.services}
                    value={selectedServices}
                    className="basic-multi-select"
                    formatOptionLabel={formatServicesLabels}
                    placeholder="Escolha os serviços a serem realizados"
                    isClearable
                    required
                  />

                  <div className="text-end mt-2 bold">
                    Total: {calculateTotalSelectServices()}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Salvar
                </button>
                <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <Spinner />
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default EditarControleServico;
