export const FIELDS =  (customers, services) => [
  {
    name: "customerId",
    type: "select",
    options: customers,
    config: {
      label: "Cliente",
      required: true,
      searchable: true,
    },
  },
  {
    name: "servicesIds",
    type: "select",
    options: services,
    config: {
      label: "Serviços",
      multiple: "chips",
      required: true,
      searchable: true,
    },
  },
];
