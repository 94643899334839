import React from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBTable, CDBTableHeader, CDBSpinner, CDBTableBody } from "cdbreact";
import { useState, useEffect } from "react";
import {
  deletePromotionById,
  listAllPromotions,
  sendFlashPromotion,
} from "../../../services/PromotionService";
import PromocoesForm from "./PromocoesForm";
import { toast } from "react-toastify";
import EditarMarketing from "./EditarMarketing";

export const Promocoes = () => {
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    setIsLoading(true);
    listPromotions();
  }, []);

  function listPromotions() {
    listAllPromotions()
      .then((response) => {
        setPromotions(response);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  }

  function sendPromotion(promo) {
    const executeAction = window.confirm(
      `Tem certeza que deseja disparar esse marketing '${promo.text}' para todos seus clientes?`
    );
    if (executeAction) {
      sendFlashPromotion(promo.id)
        .then((response) => {
          toast.success("Marketing enviado para seus clientes");
        })
        .catch((error) => {
          if (error.response.status == 422) toast.error(error.response.data);
          else toast.error("Não foi possivel enviar marketing");
        });
    }
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function deletePromotion(promotionId) {
    const executeAction = window.confirm(
      `Tem certeza que deseja excluir esse marketing?`
    );

    if (executeAction) {
      deletePromotionById(promotionId)
        .then((response) => {
          toast.success("Promoção excluída");
          listPromotions();
        })
        .catch((error) => {
          toast.error("Não foi possivel excluir esta promoção");
        });
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3>Marketing</h3>
                </div>

                <div className="d-flex mt-3 mb-1">
                  <div>
                    <PromocoesForm listPromotions={listPromotions} />
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <CDBTable responsive>
                      <CDBTableHeader>
                        <tr>
                          <th>Mensagem</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {promotions ? (
                          promotions.map((promo) => (
                            <tr key={promo.id}>
                              <td>{promo.text}</td>

                              <td>
                                <div className="d-flex">
                                  <EditarMarketing
                                    listMarketings={listPromotions}
                                    marketing={promo}
                                  />

                                  <button
                                    onClick={() => sendPromotion(promo)}
                                    type="button"
                                    className="btn btn-dark mx-2"
                                  >
                                    Enviar
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-danger mx-2"
                                    onClick={() => deletePromotion(promo.id)}
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>

                    {promotions.length === 0 && (
                      <>Nenhuma promoção cadastrada.</>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promocoes;
