import React, { useState } from "react";
import { useRef } from "react";
import { createCompany } from "../../../services/CompanyService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import ReactInputMask from "react-input-mask";
import removerSpecialChars from "../../../util/StringUtils";

export const EmpresaForm = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputCnpj = useRef("");
  const inputCorporateName = useRef("");
  const inputFantasyName = useRef("");
  const inputRepresentantName = useRef("");
  const inputRepresentantEmail = useRef("");
  const inputRepresentantCpf = useRef("");
  const inputRepresentantPhoneNumber = useRef("");
  const inputWhatsAppNumber = useRef("");
  const inputAddress = useRef("");
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      documentNumber: removerSpecialChars(inputCnpj.current.value),
      corporateName: inputCorporateName.current.value,
      fantasyName: inputFantasyName.current.value,
      representantEmail: inputRepresentantEmail.current.value,
      representantName: inputRepresentantName.current.value,
      representantDocumentNumber: removerSpecialChars(
        inputRepresentantCpf.current.value
      ),
      representantPhoneNumber:
        "55" + removerSpecialChars(inputRepresentantPhoneNumber.current.value),
      whatsAppNumber:
        "55" + removerSpecialChars(inputWhatsAppNumber.current.value),
      address: inputAddress.current.value,
    };

    createCompany(data)
      .then((response) => {
        props.listCompanies(false);
        setIsOpen(false);
        toast.success(response);
      })
      .catch((error) => {
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi possivel adicionar empresa");
      });
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i>
        Nova empresa
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de nova empresa</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-12 mb-3">
                <label htmlFor="razaoSocial" className="form-label bold">
                  Razão Social
                </label>
                <input
                  ref={inputCorporateName}
                  type="text"
                  id="razaoSocial"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="nomeFantasia" className="form-label bold">
                  Nome Fantasia
                </label>
                <input
                  ref={inputFantasyName}
                  type="text"
                  id="nomeFantasia"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  CNPJ
                </label>
                <ReactInputMask
                  ref={inputCnpj}
                  type="text"
                  id="cnpj"
                  mask="99.999.999/9999-99"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-lg-12 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  Endereço
                </label>
                <input
                  ref={inputAddress}
                  type="text"
                  id="address"
                  className="form-control"
                  required
                />
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="cnpj" className="form-label bold">
                  Whats App da empresa
                </label>
                <ReactInputMask
                  ref={inputWhatsAppNumber}
                  type="text"
                  id="cnpj"
                  className="form-control"
                  mask="(99) 99999-9999"
                  required
                />
              </div>
            </div>

            <div className="row mb-2">
              <label htmlFor="dadosResponsavel" className="form-label bold">
                Dados do responsável
              </label>
            </div>
            <div className="row mb-3">
              <div className="col col-xs-12 col-sm-6 mb-3">
                <label htmlFor="nomeResponsavel" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputRepresentantName}
                  type="text"
                  id="nomeResponsavel"
                  className="form-control"
                  required
                />
              </div>
              <div className="col col-12 col-sm-6 mb-3">
                <label htmlFor="cpfResponsavel" className="form-label bold">
                  CPF
                </label>
                <ReactInputMask
                  ref={inputRepresentantCpf}
                  id="cpfResponsavel"
                  className="form-control"
                  mask="999.999.999-99"
                  required
                />
              </div>

              <div className="col col-xs-12 col-sm-6 mb-3">
                <label htmlFor="emailResponsavel" className="form-label bold">
                  E-mail
                </label>
                <input
                  ref={inputRepresentantEmail}
                  type="email"
                  id="emailResponsavel"
                  className="form-control"
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label
                  htmlFor="telefoneResponsavel"
                  className="form-label bold"
                >
                  Telefone
                </label>
                <ReactInputMask
                  ref={inputRepresentantPhoneNumber}
                  id="telefoneResponsavel"
                  className="form-control"
                  mask="(99) 99999-9999"
                  required
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Adicionar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default EmpresaForm;
