import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function sendToWhatsAppSalesReceivablesPDF(body) {
  const loginUrl = `${API_URL}/reports/whatsapp/sales-receivables-of-customer`;
  const response = await axiosInstance.post(loginUrl, body, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function downloadSalesReceivablesPDF(body) {
  const loginUrl = `${API_URL}/reports/sales-receivables-of-customer`;
  const response = await axiosInstance.post(loginUrl, body, {
    headers: getAuthHeaders(),
    responseType: "blob",
  });

  return response.data;
}

export async function sendCashFlowResumePDF(body) {
  const loginUrl = `${API_URL}/reports/send-cash-flow-resume`;
  const response = await axiosInstance.post(loginUrl, body, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function generateReportByRange(body, delay = true) {
  const loginUrl = `${API_URL}/reports/range`;
  const response = await axiosInstance.post(loginUrl, body, {
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function generateReportCashFlow(filterDate) {
  const loginUrl = `${API_URL}/reports/cash-flow?filterDate=${filterDate}`;
  const response = await axiosInstance.post(
    loginUrl,
    {},
    { headers: getAuthHeaders() }
  );

  return response.data;
}

export async function sendServiceQuotation(data) {
  const url = `${API_URL}/reports/quotation-service`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function reportDownloadPDF(data) {
  const url = `${API_URL}/reports/download`;
  const response = await axiosInstance.post(url, data, {
    responseType: "blob",
    headers: getAuthHeaders(),
  });

  return response.data;
}

export async function sendToEmailCompanyPDF(data) {
  const url = `${API_URL}/reports/send-to-email-company`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });

  return response.data;
}
