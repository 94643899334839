import React, { useRef } from "react";
import { useState } from "react";
import { doLogin, resetPassword } from "../../services/AuthService";
import { useHistory } from "react-router-dom";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";
import isAuthorized from "../../util/RoleUtil";

export const Login = () => {
  const inputEmail = useRef("");
  const inputPassword = useRef("");
  const [companies, setCompanies] = useState([]);
  const [isOpenModal, setisOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleLogin = (e) => {
    setLoading(true);
    e.preventDefault();

    doLogin(inputEmail.current.value, inputPassword.current.value)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("userId", response.userId);
        localStorage.setItem("userName", response.userName);
        localStorage.setItem("roles", response.roles);
        localStorage.setItem("token", response.token);

        if (response.companies?.length > 1) {
          setCompanies(response.companies);
          setisOpenModal(true);
          return;
        }

        localStorage.setItem(
          "companyName",
          response.companies[0]
            ? response.companies[0].fantasyName
            : response.userName
        );

        if (isAuthorized(["ROLE_ADMIN"])) {
          history.push("/empresas");
          return;
        }
        navigateToRouteRole();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.response && error.response.status === 422) {
          if (
            error.response.data ==
            "Sua assinatura venceu entre em contato conosco"
          )
            window.location.href =
              "https://alconauto.com.br/renovar-assinatura";
          else toast.error(error.response.data);
        } else toast.error("Não foi possivel realizar login");
      });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    resetPassword(inputEmail.current.value)
      .then((response) => {
        toast.success(
          "Em alguns instantes você receberá sua nova senha em seu E-mail"
        );
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422)
          toast.error(error.response.data);
        else if (error.response && error.response.status === 400)
          toast.warning("Informe o e-mail para resetar sua senha");
        else
          toast.error(
            "Não foi possivel enviar sua nova senha no momento, tente novamente mais tarde"
          );
        setLoading(false);
      });
  };

  function navigateToRouteRole() {
    if (localStorage.getItem("roles").includes(["ROLE_OWNER"]))
      history.push("/dashboard");
    else if (localStorage.getItem("roles").includes(["ROLE_CASHIER"]))
      history.push("/caixa");
    else history.push("/controle-servicos");
  }

  return (
    <section className="vh-100" style={{ backgroundColor: "#333" }}>
      <Modal companiesList={companies} isOpen={isOpenModal} />
      <div className="container h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-sm-10 col-md-10 col-xl-6">
            <div className="card" style={{ borderRadius: "1rem" }}>
              <div className="row g-0">
                <div className="col-md-12 col-lg-12 d-flex align-content-center">
                  <div className="card-body p-4  text-black">
                    <form onSubmit={handleLogin}>
                      <div className="d-flex align-items-center mb-3 pb-1">
                        <img
                          src="/img/logo-h.png"
                          alt="logo-alcon-auto"
                          width={150}
                          height={43}
                        />
                      </div>
                      <div>
                        <h5
                          className="fw-normal mb-3 pb-3"
                          style={{ letterSpacing: "1px" }}
                        >
                          Faça login na sua conta
                        </h5>
                      </div>

                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form2Example17">
                          Seu E-mail
                        </label>
                        <input
                          type="email"
                          id="form2Example17"
                          className="form-control form-control-lg"
                          ref={inputEmail}
                          required
                        />
                      </div>
                      <div className="form-outline mb-4">
                        <label className="form-label" htmlFor="form2Example27">
                          Sua Senha
                        </label>
                        <input
                          type="password"
                          id="form2Example27"
                          className="form-control form-control-lg"
                          ref={inputPassword}
                          required
                        />
                      </div>

                      <div>
                        {loading ? (
                          <div
                            className="spinner-border mb-4"
                            role="status"
                          ></div>
                        ) : (
                          <button
                            className="btn btn-dark btn-block mb-4"
                            type="submit"
                          >
                            Acessar
                          </button>
                        )}
                      </div>
                      {!loading && (
                        <p style={{ color: "#393f81" }}>
                          Esqueceu sua senha?
                          <a
                            onClick={handleResetPassword}
                            className="btn p-0"
                            style={{ color: "#393f81", marginLeft: "5px" }}
                          >
                            <strong>Clique aqui</strong>
                          </a>
                        </p>
                      )}

                      <a
                        href=""
                        style={{ marginRight: "15px" }}
                        className="small text-muted"
                      >
                        Termos de uso
                      </a>
                      <a href="" className="small text-muted">
                        Politicas de privacidade
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
