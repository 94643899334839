import { CDBTable, CDBTableHeader, CDBTableBody } from "cdbreact";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import ConcluirVendaModal from "../modal/ConcluirVendaModal";
import Select from "react-select";
import { useEffect, useState } from "react";
import { listAllSalesByStatus } from "../../../../services/SaleService";
const dayjs = require("dayjs");

const VendasAbertasTable = (props) => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [sales, setSales] = useState();
  const [pageInfo, setPageInfo] = useState();

  useEffect(() => {
    if (props.sales) {
      setSales([...props.sales]);
    }
  }, [props.sales]);

  function handleChangeCustomer(customer) {
    if (customer) {
      setSelectedCustomer(customer);
      listSales(customer.value);
    } else {
      setSelectedCustomer();
      setSales([...props.sales]);
      setPageInfo(props.pageInfo);
    }
  }

  function listSales(customerId) {
    listAllSalesByStatus("OPENED", customerId)
      .then((response) => {
        setSales(response.data);
        setPageInfo(response.pageInfo);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>
      <>
        <div className="d-lg-flex mb-3" style={{ minWidth: "18rem" }}>
          <Select
            id="customerSelect"
            name="customers"
            classNamePrefix="select"
            options={props.customers}
            className="basic-multi-select"
            value={selectedCustomer}
            onChange={(customer) => handleChangeCustomer(customer)}
            isClearable
            formatOptionLabel={formatCustomersLabels}
            placeholder="Filtrar vendas a abertas de um cliente"
          />
        </div>

        <CDBTable responsiveSm responsiveMd>
          <CDBTableHeader>
            <tr>
              <th>Data</th>
              <th>Veículo</th>
              <th>Cliente</th>
              <th>Total</th>
              <th>Ações</th>
            </tr>
          </CDBTableHeader>
          <CDBTableBody>
            {sales &&
              sales.map((sale) => (
                <tr key={sale.id}>
                  <td>{dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                  <td>{sale.vehicleInfo}</td>
                  <td>
                    {sale.customer ? sale.customer.name : "Não identificado"}
                  </td>
                  <td className="bold">{currencyBRFormat(sale.total)}</td>
                  <td>
                    <ConcluirVendaModal
                      sale={sale}
                      listSales={props.listSales}
                      customers={props.customers}
                    />
                  </td>
                </tr>
              ))}
          </CDBTableBody>
        </CDBTable>
      </>
      <div>
        {sales && sales.length === 0 ? (
          <>
            Nenhuma venda a aberta
            {selectedCustomer ? " de " + selectedCustomer.label : ""}
          </>
        ) : (
          <div className="d-flex justify-content-end">
            <label>Total a Receber</label>
            <div className="mx-2 ">
              {currencyBRFormat(
                sales && sales
                  .map((sale) => sale.total)
                  .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VendasAbertasTable;

const formatCustomersLabels = (customer) => (
  <>
    <span>{customer.label}</span>
    <span> - {customer.key}</span>
  </>
);
