import React, { useEffect, useState } from "react";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import isAuthorized from "./util/RoleUtil";

const Sidebar = (props) => {
  const history = useHistory();
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    setToggled(props.toggled);
  }, [props.toggled]);

  function doLogout() {
    history.push("/");
    localStorage.clear();
  }

  return (
    <div
      className="app"
      style={{ display: "flex", height: "100%", overflow: "scroll initial" }}
    >
      <CDBSidebar
        textColor="#fff"
        backgroundColor="#333"
        toggled={toggled}
        minWidth="0px"
      >
        <CDBSidebarHeader>
          <img
            src="/img/logo-h-branca.png"
            alt="logo-alcon-auto-branca"
            width={150}
            height={43}
          />
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            {isAuthorized(["ROLE_ADMIN"]) ? (
              <>
                <NavLink exact to="/empresas" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="building">
                    empresas
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/whatsapp-api"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="phone">
                    Whats app API
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {isAuthorized(["ROLE_CASHIER"]) ? (
              <>
                <NavLink exact to="/caixa" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="cash-register">
                    caixa
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/despesas" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="money-bill">
                    despesas
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  exact
                  to="/controle-servicos"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="clock">
                    controle de serviços
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/clientes" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="address-book">
                    clientes
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/marketing" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="bullhorn">
                    Marketing
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}
            
            {isAuthorized(["ROLE_OPERATIONAL"]) ? (
              <>
                <NavLink
                  exact
                  to="/controle-servicos"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="clock">
                    controle de serviços
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            {isAuthorized(["ROLE_OWNER"]) ? (
              <>
                <NavLink exact to="/dashboard" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="columns">
                    dashboard
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/caixa" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="cash-register">
                    caixa
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/despesas" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="envelope-open-text">
                    despesas
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  exact
                  to="/controle-servicos"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="tasks">
                    controle de serviços
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/agendamentos" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="calendar">
                  agendamentos
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/relatorios" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="file">
                    relatórios
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/servicos" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="car">serviços</CDBSidebarMenuItem>
                </NavLink>

                <NavLink
                  className="d-none"
                  exact
                  to="/pecas"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="wrench">peças</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/clientes" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="address-book">
                    clientes
                  </CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/veiculos" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="car">veículos</CDBSidebarMenuItem>
                </NavLink>

                <NavLink exact to="/marketing" activeClassName="activeClicked">
                  <CDBSidebarMenuItem icon="bullhorn">
                    Marketing
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/funcionarios"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="user">
                    funcionários
                  </CDBSidebarMenuItem>
                </NavLink>
                <NavLink
                  exact
                  to="/configuracoes"
                  activeClassName="activeClicked"
                >
                  <CDBSidebarMenuItem icon="cog">
                    configurações
                  </CDBSidebarMenuItem>
                </NavLink>
              </>
            ) : (
              <></>
            )}

            <NavLink
              exact
              onClick={() => doLogout()}
              to="/"
              activeClassName="activeClicked"
            >
              <CDBSidebarMenuItem icon="sign-out-alt">sair</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>
      </CDBSidebar>
    </div>
  );
};

export default Sidebar;
