import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function getCustomerHistoryByPage(customerId, page) {
  const serviceControllUrl = `${API_URL}/sales/customers?size=10&customerId=${customerId}&page=${page}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function getCustomerHistory(customerId) {
  const serviceControllUrl = `${API_URL}/sales/customers?size=10&customerId=${customerId}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllSalesByStatus(status, customerId) {
  const size = status == 'OPENED' ? 100 : 10;
  const serviceControllUrl = `${API_URL}/sales?status=${status}&size=${size}${customerId ? `&customerId=${customerId}` : ''}`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllSalesByStatusAndPage(status, page) {
  const serviceControllUrl = `${API_URL}/sales?status=${status}&page=${page}&size=10`;
  const response = await axiosInstance.get(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createSale(data) {
  const serviceControllUrl = `${API_URL}/sales`;
  const response = await axiosInstance.post(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function paySale(saleId, data) {
  const serviceControllUrl = `${API_URL}/sales/${saleId}/pay`;
  const response = await axiosInstance.put(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function paySaleReceivables(data) {
  const serviceControllUrl = `${API_URL}/sales/pay-receivables`;
  const response = await axiosInstance.put(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateSale(saleId, data) {
  const serviceControllUrl = `${API_URL}/sales/${saleId}`;
  const response = await axiosInstance.put(serviceControllUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deleteSaleById(saleId) {
  const serviceControllUrl = `${API_URL}/sales/${saleId}`;
  const response = await axiosInstance.delete(serviceControllUrl, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

