import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBSpinner } from "cdbreact";
import { generateReportByRange } from "../../../services/ReportService";
import sleep from "../../../util/SleepUtils";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import "dayjs/locale/pt-br";
import AccordionRelatorio from "./AccordionRelatorio";
const dayjs = require("dayjs");
registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

export const Relatorios = () => {
  const [reports, setReports] = useState();
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
  }, []);

  function treatTitle() {
    let title = `${dayjs(startDate).format("DD/MM/YYYY")} até ${dayjs(
      endDate
    ).format("DD/MM/YYYY")}`;

    if (
      dayjs(startDate).format("DD/MM/YYYY") ==
      dayjs(endDate).format("DD/MM/YYYY")
    )
      title = "Hoje";
    return title;
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    sleep(2000);

    const body = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
    };

    generateReportByRange(body)
      .then((response) => {
        setReports(response);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422)
          toast.error(error.response.data);
        else toast.error("Nao foi possivel gerar relatorio");
        console.log(error);
        setLoading(false);
      });
  }

  function handleChangeStartDate(date) {
    setStartDate(date);
    setReports();
  }

  function handleChangeEndtDate(date) {
    setEndDate(date);
    setReports();
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3 className="font-weight-bold">Relatórios</h3>
                </div>
                <hr />
                <form onSubmit={handleSubmit}>
                  <div className="row mb-3">
                    <div className="col-lg-2">
                      <div className="mb-2">
                        <label>Data inicial</label>
                      </div>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        locale="pt-BR"
                        selected={startDate}
                        todayButton="Hoje"
                        onMonthChange={(data) =>  setStartDate(data)}
                        onChange={(date) => handleChangeStartDate(date)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <div className="mb-2 mt-lg-0 mt-3">
                        <label>Data final</label>
                      </div>

                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        maxDate={new Date()}
                        locale="pt-BR"
                        selected={endDate}
                        todayButton="Hoje"
                        onMonthChange={(data) =>  setStartDate(data)}
                        onChange={(date) => handleChangeEndtDate(date)}
                      />
                    </div>

                    <div className="col-lg-2 mt-lg-0 mt-3 align-self-end">
                      <button className="btn btn-dark" type="submit">
                        Gerar
                      </button>
                    </div>
                  </div>
                </form>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3 mt-5">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    {reports && (
                      <div>
                        <AccordionRelatorio
                          report={reports}
                          title={treatTitle()}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Relatorios;
