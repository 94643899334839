import React, { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { updateFee } from "../../../services/PaymentMethodService";

const EditarFormaPagamentoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputFee = useRef();

  const toggle = () => setIsOpen(!isOpen);

  useState(() => {}, [isOpen]);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      id: props.pay.id,
      fee: parseFloat(inputFee.current.value),
    };

    updateFee(data)
      .then((response) => {
        toast.success(response);
        props.listPayments();
        setIsOpen(false);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast.error(error);
      });
  }

  return (
    <div>
      <button
        className="btn btn-dark"
        type="button"
        onClick={() => setIsOpen(true)}
      >
        Editar
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Editar taxa {props.pay && props.pay.name}</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <label>Taxa %</label>
                <input
                  ref={inputFee}
                  className="form-control mt-2"
                  defaultValue={props.pay && props.pay.fee}
                  type="number"
                  min={0}
                  max={99.99}
                  step={0.01}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <div className="d-flex">
                <button className="btn btn-dark mx-3">Salvar</button>

                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};
export default EditarFormaPagamentoModal;
