import React from "react";
import Routes from "./routes/Routes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTopButton from "./components/ScrollToTopButton";

function App() {
  return (
    <>
      <ToastContainer theme="dark" />
      <Routes />
      <ScrollToTopButton />
    </>
  );
}

export default App;
