import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function getLoylatyCofigByCompanyId() {
  const loayltyUrl = `${API_URL}/loyalty/config`;
  const response = await axiosInstance.get(loayltyUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createOrUpdateConfigByCompanyId(data) {
  const loayltyUrl = `${API_URL}/loyalty/config`;
  const response = await axiosInstance.post(loayltyUrl, data, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createOrIncrementLoyaltyCardByCompanyId(data) {
  const loayltyUrl = `${API_URL}/loyalty/card`;
  const response = await axiosInstance.post(loayltyUrl, data, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function getLoyaltyCardCompleted(customerId) {
  const loayltyUrl = `${API_URL}/loyalty/customer/${customerId}/completed`;
  const response = await axiosInstance.get(loayltyUrl, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}