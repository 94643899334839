import React, { useEffect, useRef, useState } from "react";
import currencyBRFormat from "../../../util/CurrencyUtils";
import {
  reportDownloadPDF,
  sendToEmailCompanyPDF,
} from "../../../services/ReportService";
import FileSaver from "file-saver";
import { toast } from "react-toastify";
const dayjs = require("dayjs");

export const AccordionRelatorio = (props) => {
  const bottomRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    const tooltipList = tooltipTriggerList.map((tooltipTriggerEl) => {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
    return () => {
      tooltipList.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  function download() {
    setLoading(true);
    props.report.title =
      props.title === "Hoje"
        ? dayjs(new Date()).format("DD/MM/YYYY")
        : props.title;
    reportDownloadPDF(props.report)
      .then((response) => {
        var blob = new Blob([response], { type: "application/pdf" });
        FileSaver.saveAs(
          blob,
          `Fechamento de Caixa detalhado ${props.report.title.replaceAll(
            "/",
            "-"
          )}.pdf`
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Erro ao baixar o arquivo:", error.message);
      });
  }

  function sendToEmailCompany() {
    setLoading(true);
    props.report.title =
      props.title === "Hoje"
        ? dayjs(new Date()).format("DD/MM/YYYY")
        : props.title;
    sendToEmailCompanyPDF(props.report)
      .then((response) => {
        toast.success(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data);
        console.error("Erro ao baixar o arquivo:", error.message);
      });
  }

  return (
    <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button bg-dark text-light"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            {props.title}
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="row">
              <div className="col-lg-3">
                <p className="bold">Faturamento</p>
                {props.report.paymentMethods.length > 0 ? (
                  props.report.paymentMethods.map((payment) => (
                    <div key={payment.name}>
                      <div>{payment.name}</div>
                      <p>
                        <i className="fas  fa-angle-up p-1 text-success" />
                        {currencyBRFormat(payment.value)}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>{currencyBRFormat(0.0)}</p>
                )}
              </div>

              <div className="col-lg-3">
                <p className="bold">Despesas Fixas </p>
                {props.report.fixedExpenses.length > 0 ? (
                  props.report.fixedExpenses.map((expense) => (
                    <div>
                      <div> {expense.description} </div>
                      <p>
                        <i className="fas fa-angle-down p-1 text-danger" />
                        {currencyBRFormat(expense.value)}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>{currencyBRFormat(0.0)}</p>
                )}
              </div>

              <div className="col-lg-3">
                <p className="bold">Despesas Variáveis </p>

                {props.report.variableExpenses.map((expense) => (
                  <div>
                    <div className="createdAtReport">
                      {dayjs(expense.createdAt).format("DD/MM/YYYY")}
                    </div>
                    <el
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title={dayjs(expense.createdAt).format("DD/MM/YYYY")}
                    >
                      <div>{expense.description}</div>
                    </el>
                    <p>
                      <i className="fas fa-angle-down p-1 text-danger" />
                      {currencyBRFormat(expense.value)}
                    </p>
                  </div>
                ))}

                <div>
                  <div title="Taxas da máquina de cartão e pix se houver.">
                    Taxas pagamentos
                  </div>
                  <p>
                    <i className="fas fa-angle-down p-1 text-danger" />
                    {currencyBRFormat(props.report.paymentFeeCosts)}
                  </p>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="bold">Veículos atendidos</div>
                <p> {props.report.totalVehicles ?? 0}</p>

                <div
                  className="bold"
                  title="Total de descontos dado aos clientes no pagamento da venda."
                >
                  Descontos vendas
                </div>
                <p>
                  <i className="fas fa-angle-down p-1 text-danger" />
                  {currencyBRFormat(props.report.totalDiscounts)}
                </p>
              </div>

              <hr />
              <div className="col-lg-3">
                <div className="bold">Faturamento Bruto</div>
                <p>
                  <i className="fas fa-angle-up p-1 text-success" />
                  {currencyBRFormat(props.report.grossRevenue)}
                </p>
              </div>
              <div className="col-lg-3">
                <div className="bold">Total Despesas Fixas</div>
                <p>
                  <i className="fas fa-angle-down p-1 text-danger" />
                  {currencyBRFormat(props.report.totalFixedExpenses)}
                </p>
              </div>
              <div className="col-lg-3">
                <div className="bold">Total Despesas Variáveis</div>
                <p>
                  <i className="fas fa-angle-down p-1 text-danger" />{" "}
                  {currencyBRFormat(props.report.totalVariableExpenses)}
                </p>
              </div>
              <div ref={bottomRef} className="col-lg-3">
                <div className="bold">Faturamento Líquido</div>
                <p className={props.report.netRevenue < 0 ? "red-text" : ""}>
                  {currencyBRFormat(props.report.netRevenue)}
                </p>
              </div>
              <hr />
              <div className="d-flex">
                {!loading ? (
                  <>
                    <button className="btn btn-dark" onClick={() => download()}>
                      <i className="fa fa-download me-2" aria-hidden="true"></i>
                      Baixar
                    </button>
                    <button
                      className="btn btn-dark mx-lg-3 mx-2"
                      onClick={() => sendToEmailCompany()}
                    >
                      <i className="fa fa-envelope me-2" aria-hidden="true"></i>
                      Enviar
                    </button>
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionRelatorio;
