import { BrowserRouter, Redirect, Route } from "react-router-dom";
import { Dashboard } from "../pages/private/dashboard/Dashboard";
import Login from "../pages/public/Login";
import Empresas from "../pages/private/empresas/Empresas";
import Caixa from "../pages/private/caixa/Caixa";
import ControleServico from "../pages/private/controle-servicos/ControleServico";
import Servicos from "../pages/private/servicos/Servicos";
import Funcionarios from "../pages/private/funcionarios/Funcionarios";
import Configuracoes from "../pages/private/configuracoes/Configuracoes";
import Clientes from "../pages/private/clientes/Clientes";
import Promocoes from "../pages/private/promocoes/Promocoes";
import Relatorios from "../pages/private/relatorios/Relatorios";
import Despesas from "../pages/private/despesas/Despesas";
import WhatsApp from "../pages/private/whatsapp/Whatsapp";
import Pecas from "../pages/private/pecas/Pecas";
import Veiculos from "../pages/private/veiculos/Veiculos";
import Agendamentos from "../pages/private/angedamentos/Agendamento";

const Routes = () => {
  function PrivateRoute({ children, requiredRoles, ...rest }) {
    return (
      <Route
        {...rest}
        render={() => {
          const token = localStorage.getItem("token");
          const userRoles = localStorage.getItem("roles");
          const isAuthorized = requiredRoles.includes(userRoles);

         // if (!token) return <Redirect to="/" />;
          return token ? children : <Redirect to="/" />;
        }}
      />
    );
  }

  return (
    <BrowserRouter>
      <Route exact path="/" component={Login} />
      
      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER", "ROLE_OPERATIONAL"]}>
        <Route exact path="/agendamentos" component={Agendamentos} />
      </PrivateRoute>
      
      <PrivateRoute requiredRoles={["ROLE_OWNER"]}>
        <Route exact path="/dashboard" component={Dashboard} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER"]}>
        <Route exact path="/relatorios" component={Relatorios} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route exact path="/despesas" component={Despesas} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route exact path="/veiculos" component={Veiculos} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_ADMIN"]}>
        <Route exact path="/empresas" component={Empresas} />
      </PrivateRoute>
      <PrivateRoute requiredRoles={["ROLE_ADMIN"]}>
        <Route exact path="/whatsapp-api" component={WhatsApp} />
      </PrivateRoute>
      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route path="/marketing" component={Promocoes} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route exact path="/caixa" component={Caixa} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER", "ROLE_OPERATIONAL"]}>
        <Route path="/controle-servicos" component={ControleServico} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route path="/funcionarios" component={Funcionarios} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER"]}>
        <Route path="/servicos" component={Servicos} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER"]}>
        <Route path="/pecas" component={Pecas} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER"]}>
        <Route path="/configuracoes" component={Configuracoes} />
      </PrivateRoute>

      <PrivateRoute requiredRoles={["ROLE_OWNER", "ROLE_CASHIER"]}>
        <Route path="/clientes" component={Clientes} />
      </PrivateRoute>

    </BrowserRouter>
  );
};

export default Routes;
