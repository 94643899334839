import React, { useState } from "react";
import { useRef } from "react";
import { updatePromotion } from "../../../services/PromotionService";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const EditarMarketing = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputText = useRef("");
  const inputDate = useRef("");
  const inputHour = useRef("");
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      text: inputText.current.value,
      id: props.marketing.id
    };

    updatePromotion(data)
      .then((response) => {
        props.listMarketings();
        setIsOpen(false);
        toast.success("Marketing atualizado");
      })
      .catch((error) => {
        if (error.response.status == 422) toast.error(error.response.data);
        else toast.error("Não foi atualizar este marketing");
      });
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen(true)}
        type="button"
        className="btn btn-dark"
        title="Editar este Marketing"
      >
        <i className="fa fa-edit"></i>
      </button>

      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Editar dados do marketing</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="serviceName" className="form-label bold">
                  Conteúdo da mensagem
                </label>
                <textarea
                  defaultValue={props.marketing.text}
                  ref={inputText}
                  type="text"
                  id="serviceName"
                  className="form-control"
                  rows={8}
                  required
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button type="submit" className="btn btn-dark">
              Salvar
            </button>
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="btn btn-danger"
            >
              Cancelar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default EditarMarketing;
