export const TRANSLATIONS = {  
    navigation: {
      month: "Mês",
      week: "Semana",
      day: "Dia",
      today: "Hoje",
      agenda: "Agenda",
    },
    validation: {
      required: "Obrigatório",
    },
    form: {
      addTitle: "Novo agendamento",
      editTitle: "Editar agendamento",
      confirm: "Salvar",
      delete: "Desmarcar",
      cancel: "Cancelar",
    },
    event: {
      title: "Titulo",
      subtitle: "Descriçao",
      customer: "Cliente",
      start: "Inicio",
      end: "Fim",
      allDay: "Dia inteiro ",
    },
    moreEvents: "Mais...",
    noDataToDisplay: "Sem agendamentos",
    loading: "Carregando..."
  };