import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function employeesComissionsEnabled() {
  const url = `${API_URL}/users/comissions-enabled`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function getEmployeesComissions(startDate, endDate, page) {
  const url = `${API_URL}/users/comissions?startDate=${startDate}&endDate=${endDate}${page ? `&page=${page}` : ''}`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function historyEmployee(employeeId, filterDate) {
  const url = `${API_URL}/users/history/${employeeId}?filterDate=${filterDate}`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function listAllUsers(delay= true) {
  const url = `${API_URL}/users`;
  const response = await axiosInstance.get(url, {
    headers: getAuthHeaders(),
  });
  
  return response.data;
}

export async function createUser(data) {
  const url = `${API_URL}/users`;
  const response = await axiosInstance.post(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateUser(userId, data) {
  const url = `${API_URL}/users/${userId}`;
  const response = await axiosInstance.patch(url, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function detachUser(userId) {
  const url = `${API_URL}/users/detach/${userId}`;
  const response = await axiosInstance.patch(url, {}, {
    headers: getAuthHeaders(),
  });
  return response.data;
}
