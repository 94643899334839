import axiosInstance from "../interceptors/axiosInterceptor";
import getAuthHeaders from "../util/HeadersUtil";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:8080";

export async function findAllCarsByCompany() {
  const loginUrl = `${API_URL}/vehicles`;
  const response = await axiosInstance.get(loginUrl, { headers: getAuthHeaders() });
  return response.data;
}

export async function findAllCarsByCompanyPage(page) {
  const loginUrl = `${API_URL}/vehicles?page=${page}`;
  const response = await axiosInstance.get(loginUrl, { headers: getAuthHeaders() });
  return response.data;
}

export async function findByLicensePlate(licensePlate) {
  const loginUrl = `${API_URL}/vehicles/license-plate/${licensePlate}`;
  const response = await axiosInstance.get(loginUrl, { headers: getAuthHeaders() });
  return response.data;
}

export async function createCar(data) {
  const loginUrl = `${API_URL}/vehicles`;
  const response = await axiosInstance.post(loginUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function updateCar(data) {
  const loginUrl = `${API_URL}/vehicles`;
  const response = await axiosInstance.put(loginUrl, data, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function deleteCar(id) {
  const loginUrl = `${API_URL}/vehicles/${id}`;
  const response = await axiosInstance.delete(loginUrl, {
    headers: getAuthHeaders(),
  });
  return response.data;
}

export async function removeCustomerVehicle(id) {
  const loginUrl = `${API_URL}/vehicles/${id}/remove-customer`;
  const response = await axiosInstance.delete(loginUrl, {
    headers: getAuthHeaders(),
  });
  return response.data;
}
