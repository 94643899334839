import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import {
  deleteServico,
  listAllServicesByPage,
} from "../../../services/ServicoService";
import { CDBTable, CDBSpinner, CDBTableHeader, CDBTableBody } from "cdbreact";
import ServicoForm from "./ServicoForm";
import currencyBRFormat from "../../../util/CurrencyUtils";
import EditarServicoModal from "./EditarServicoModal";
import { toast } from "react-toastify";
import PaginationBootstrap from "../../../components/PaginationBootstrap";

export const Servicos = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [pageInfo, setPageInfo] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    setIsLoading(true);
    listServicosPage();
  }, []);

  function listServicosPage(page) {
    listAllServicesByPage(page)
      .then((response) => {
        setServices(response.data);
        setPageInfo(response.pageInfo);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }

  function remove(id) {
    const executeAction = window.confirm(
      `Confirma a exclusão irreversível desse serviço?`
    );

    if (executeAction) {
      deleteServico(id)
        .then((response) => {
          listServicosPage();
          toast.success(response);
        })
        .catch((error) => {
          if (error.response && error.response.status == 422)
            toast.warn(error.response.data);
          else toast.error("Não foi possivel exluir este serviço");
        });
    }
  }

  function calculateLucro(price, cost) {
    return currencyBRFormat(parseFloat(price - cost));
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3">
                <div className="d-flex justify-content-between">
                  <h3>Serviços</h3>

                  <div className="mb-3 d-flex d-none">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pesquisar por nome"
                    />
                    <button className="btn btn-dark mx-3">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>

                <div className="d-flex mt-3 mb-1">
                  <div>
                    <ServicoForm listServicos={listServicosPage} />
                  </div>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <>
                    <CDBTable responsiveSm responsiveMd small>
                      <CDBTableHeader>
                        <tr>
                          <th>Nome</th>
                          <th>Preço</th>

                          <th>Previsão</th>
                          <th>Ações</th>
                        </tr>
                      </CDBTableHeader>
                      <CDBTableBody>
                        {services ? (
                          services.map((service) => (
                            <tr key={service.id}>
                              <td style={{ width: "23em" }}>{service.name}</td>
                              <td>{currencyBRFormat(service.price)}</td>
                              <td>
                                {service.estimatedDays > 0
                                  ? service.estimatedDays + " dias"
                                  : ""}{" "}
                                {service.estimatedMinutes > 0
                                  ? service.estimatedMinutes + " minutos"
                                  : ""}{" "}
                              </td>

                              <td>
                                <div className="d-flex">
                                  <EditarServicoModal
                                    listServicos={listServicosPage}
                                    service={service}
                                  />

                                  <button
                                    onClick={() => remove(service.id)}
                                    className="btn btn-danger mx-3"
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <></>
                        )}
                      </CDBTableBody>
                    </CDBTable>

                    {services.length > 0 ? (
                      <PaginationBootstrap
                        pageInfo={pageInfo}
                        goToPage={listServicosPage}
                      />
                    ) : (
                      <>Nenhum serviço cadastrado.</>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicos;
