import React, { useEffect, useState } from "react";
import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { CDBTable, CDBTableHeader, CDBSpinner, CDBTableBody } from "cdbreact";
import {
  listAllUsers,
  detachUser,
  employeesComissionsEnabled,
} from "../../../services/UserService";
import EditarUsuarioModal from "./EditarUsuarioModal";
import AdicionarUsuarioModal from "./AdicionarUsuarioModal";
import { toast } from "react-toastify";
import { HistoricoServicosUsuario } from "./HistoricoServicosUsuario";
import RelatorioComissoesModal from "./RelatorioComissoesModal";

export const Funcionarios = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [employee, setEmployee] = useState();
  const [comissionEnabled, setComissionEnabled] = useState(false);
  const [relatorioComissionOpen, setRelatorioComissionOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSidebarToggle();
    listUsers();
  }, []);

  function listUsers(delay) {
    setIsLoading(true);
    employeesComissionsEnabled().then((response) => {
      setComissionEnabled(response);
    });
    listAllUsers(delay)
      .then((response) => {
        setUsers(response);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error("[listUsers] error: ", e);
      });
  }

  function removeUser(userId) {
    const execute = window.confirm(
      "Você tem certeza que de seja excluir este usuário?"
    );

    if (execute) {
      detachUser(userId)
        .then((response) => {
          toast.success(response);
          listUsers(false);
        })
        .catch((e) => {
          console.error("[removeUser] error: ", e);
        });
    }
  }

  function disableRemoveButton(userId) {
    const disable = localStorage.getItem("userId") == userId;
    return disable;
  }

  function handleSidebarToggle() {
    const isMobile = window.innerWidth <= 720;
    if (isMobile) {
      setToggled(true);
    }
  }

  function handleHistoryClick(employee) {
    setEmployee(employee);
    setOpenHistory(true);
  }

  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div style={{ height: "100%" }}>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3 w-100">
                <div className="d-flex justify-content-between mb-3">
                  <h3>Funcionários</h3>
                </div>
                <div className="d-flex mt-3 mb-3">
                  <AdicionarUsuarioModal listUsers={listUsers} />

                  {comissionEnabled && (
                    <>
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          setRelatorioComissionOpen(!relatorioComissionOpen)
                        }
                      >
                        <i className="fa fa-file me-2"></i> Relatório de
                        comissões
                      </button>

                      {relatorioComissionOpen && (
                        <RelatorioComissoesModal
                          setRelatorioComissionOpen={setRelatorioComissionOpen}
                        />
                      )}
                    </>
                  )}

                  {openHistory && (
                    <HistoricoServicosUsuario
                      employee={employee}
                      openHistory={openHistory}
                      setOpenHistory={setOpenHistory}
                    />
                  )}
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center p-3">
                    <CDBSpinner dark />
                  </div>
                ) : (
                  <CDBTable responsiveSm responsiveMd>
                    <CDBTableHeader>
                      <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Função</th>
                        {comissionEnabled && <th>Comissão</th>}
                        <th>Ações</th>
                      </tr>
                    </CDBTableHeader>
                    <CDBTableBody>
                      {users ? (
                        users.map((user) => (
                          <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>
                              {user.roles.map((role) => role.value).join(", ")}
                            </td>
                            {comissionEnabled && <td>{user.comission}%</td>}
                            <td>
                              <div className="d-flex">
                                <EditarUsuarioModal
                                  user={user}
                                  listUsers={listUsers}
                                />

                                <button
                                  onClick={() => handleHistoryClick(user)}
                                  type="button"
                                  className="btn btn-dark mx-3"
                                  title="Histórico de serviços realizado pelo funcionário"
                                >
                                  <i className="fa fa-history"></i>
                                </button>

                                {!disableRemoveButton(user.id) && (
                                  <button
                                    onClick={() => removeUser(user.id)}
                                    className="btn btn-danger"
                                  >
                                    <i className="fa fa-trash" />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </CDBTableBody>
                  </CDBTable>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Funcionarios;
