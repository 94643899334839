import React, { useState } from "react";
import { useRef } from "react";
import { createServico } from "../../../services/ServicoService";
import { toast } from "react-toastify";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const ServicoForm = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [finalValue, setFinalValue] = useState(0.0);
  const [estimatedMinutes, setEstimatedMinutes] = useState(0);
  const [estimatedDays, setEstimatedDays] = useState(0);
  const inputPrice = useRef("");
  const inputName = useRef("");
  const inputCost = useRef("");
  const [loading, setLoading] = useState(false);
  const [remarketing, setRemarketing] = useState(false);
  const inputSendRemarketingInDays = useRef("");
  const inputRemarketingMessage = useRef("");
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      companyDocumentNumber: localStorage.getItem("companyDocumentNumber"),
      price: parseFloat(inputPrice.current.value),
      name: inputName.current.value,
      estimatedDays: estimatedDays,
      estimatedMinutes: estimatedMinutes,
      cost: parseFloat(inputCost.current.value),
    };

    createServico(data)
      .then((response) => {
        props.listServicos(false);
        setIsOpen(false);
        setFinalValue(0);
        toast.success("Serviço adicionado");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 422) toast.warn(error.response.data);
        else toast.error("Não foi possivel adicionar serviço");
      });
  }

  function calculateFinalValue() {
    let cost = inputCost.current.value ?? 0;
    let price = inputPrice.current.value ?? 0;

    try {
      setFinalValue(parseFloat(price - cost));
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i>
        Novo serviço
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de novo serviço</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="serviceName" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputName}
                  type="text"
                  id="serviceName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Preço
                </label>
                <input
                  ref={inputPrice}
                  id="preco"
                  type="number"
                  min={1}
                  step={0.01}
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col-lg-12">
                <label htmlFor="tempoEstimado" className="form-label bold">
                  Previsão de entrega
                </label>
                <div className="row mt-2">
                  <div className="col-lg-2">
                    <label htmlFor="tempoEstimado" className="form-label">
                      Dias
                    </label>
                    <input
                      className="form-control"
                      defaultValue={estimatedDays}
                      onChange={(e) => setEstimatedDays(e.target.value)}
                      type="number"
                      max={180}
                      min={0}
                      required
                    />
                  </div>
                  <div className="col-lg-2 mt-lg-0 mt-2">
                    <label htmlFor="tempoEstimado" className="form-label">
                      Minutos
                    </label>
                    <input
                      className="form-control"
                      defaultValue={estimatedMinutes}
                      onChange={(e) => setEstimatedMinutes(e.target.value)}
                      type="number"
                      max={180}
                      min={0}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mt-3 d-none">
                <div className="form-check form-switch">
                  <input
                    checked={remarketing}
                    onChange={() => setRemarketing(!remarketing)}
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                  />
                  <label
                    className="form-check-label bold"
                    for="flexSwitchCheckDefault"
                  >
                    Remarketing
                  </label>
                </div>
                {remarketing && (
                  <div className="row mt-2">
                    <div className="col-lg-12 d-flex">
                      <div className="align-self-center me-3">Enviar em</div>
                      <div>
                        <input
                          className="form-control"
                          ref={inputSendRemarketingInDays}
                          defaultValue={15}
                          max={180}
                          type="number"
                          min="1"
                          required
                        />
                      </div>
                      <div className="align-self-center mx-3"> dias</div>
                    </div>

                    <div className="col-lg-12 mt-2">
                      <textarea
                        ref={inputRemarketingMessage}
                        className="form-control mt-2"
                        placeholder={`Digite a mensagem que o cliente vai receber após relaziar o serviço dentro de dias definidos referente a esse serviço`}
                        required
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Adicionar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default ServicoForm;
