import Navbar from "../../../Navbar";
import Sidebar from "../../../Sidebar";
import { useEffect, useState } from "react";
import { connect, disconnect, status } from "../../../services/WhatsappService";

export const WhatsApp = () => {
  const [toggled, setToggled] = useState(false);
  const [whatsAppConnected, setWhatsAppConnected] = useState(false);
  const [qrcode, setQrcode] = useState();

  useEffect(() => {
    statusWhatsApp();
  }, [])

  function connectWhatsApp() {
    connect(false)
      .then((response) => {
        console.log(response);
        setQrcode(response.base64);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function disconnectWhatsApp(e) {
    e.preventDefault();

    const executeAction = window.confirm(
      "Tem certeza que deseja desconectar seu Whats App?\nMensagens de controle de serviço, marketing e outras não poderão mais ser enviadas."
    );

    if (executeAction) {
      disconnect()
        .then((response) => {
          setQrcode();
          statusWhatsApp();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  function statusWhatsApp() {
    status(false)
      .then((response) => {
        setWhatsAppConnected(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div className="d-flex">
      <div>
        <Sidebar toggled={toggled} />
      </div>
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexFlow: "column",
          height: "100vh" ,
          overflowX: "hidden"
        }}
      >
        <Navbar toggled={toggled} setToggled={setToggled} />
        <div>
          <div
            style={{
              padding: "20px 5%",
            }}
          >
            <div>
              <div className="mt-3 w-100">
                <div className="d-flex justify-content-between mb-3">
                    <div>
                    <h3 className="mb-3">Whats App API</h3>
                    </div>
               
                  <div>
                    {!whatsAppConnected ? (
                      <button
                        className="btn"
                        onClick={() => connectWhatsApp()}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="fa fa-power-off text-success text-success me-1"></i>Conectar WhatsApp
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={(e) => disconnectWhatsApp(e)}
                      >
                         <i className="fa fa-power-off text-success text-danger  me-1"></i>Desconectar WhatsApp
                      </button>
                    )}
                  </div>
                  <QRCodeModal qrcode={qrcode} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const QRCodeModal = (props) => {
    return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Escaneie este QRCode
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              {props.qrcode ? (
                <img className="w-100" src={props.qrcode} />
              ) : (
                <div className="spinner-border mb-4" role="status" />
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-dark" data-bs-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default WhatsApp;