import React, { useEffect, useState } from "react";
import {
  getCustomerHistory,
  getCustomerHistoryByPage,
} from "../../../../services/SaleService";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import dayjs from "dayjs";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import PaginationBootstrap from "../../../../components/PaginationBootstrap";
import { getLoyaltyCardCompleted } from "../../../../services/LoyaltyService";

export const HistoricoCliente = (props) => {
  const [customerHistory, setCustomerHisotry] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [pageInfo, setPageInfo] = useState({});
  const [loyaltyCardInfo, setLoyaltyCardInfo] = useState();
  const toggle = () => props.setHistoryOpen(false);

  useEffect(() => {
    setIsOpen(props.historyOpen);

    getCustomerHistory(props.customer.id)
      .then((response) => {
        setPageInfo(response.pageInfo);
        setCustomerHisotry(response.data);
      })
      .catch((error) => console.error(error));

      getLoyaltyCardCompleted(props.customer.id)
      .then((response) => {
        setLoyaltyCardInfo(response);
      })
      .catch((error) => console.error(error));
  }, [props]);

  function customerHistoryByPagePage(page) {
    getCustomerHistoryByPage(props.customer.id, page)
      .then((response) => {
        setPageInfo(response.pageInfo);
        setCustomerHisotry(response.data);
      })
      .catch((error) => console.error(error));
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Histórico de serviços</ModalHeader>

        <ModalBody>
          {customerHistory.length == 0 ? (
            <>Sem histórico de serviços para esse cliente</>
          ) : (
            <div>
              <div>
                <label>
                  <b className="bold">Cliente:</b> {props.customer.name}
                </label>
              </div>
              {loyaltyCardInfo && (
                <div>
                <label>
                  <b className="bold">Cartão Fidelidade: </b>
                  {loyaltyCardInfo.servicesCount}
                </label>
                <div
                  className="progress mt-1"
                  role="progressbar"
                >
                  <div className="progress-bar" style={{ width: loyaltyCardInfo.percentProgress}}></div>
                </div>
              </div>
              
              )}

              <table className="table mt-4">
                <thead>
                  <th>Data</th>
                  <th>Placa/Veículo</th>
                  <th>Funcionário</th>
                  <th>Serviços</th>
                  <th>Preço</th>
                </thead>
                <tbody>
                  {customerHistory.map((s) => (
                    <tr key={s.id}>
                      <td width="10%">
                        {dayjs(s.createdAt).format("DD/MM/YYYY")}
                      </td>
                      <td width="20%">{s.vehicleInfo}</td>
                      <td width="20%">{s.employeeName}</td>
                      <td width="30%">
                        {s.servicesAndParts.map((svc) => svc.name).join(", ")}
                      </td>
                      <td width="10%">{currencyBRFormat(s.total)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-none d-flex justify-content-end mt-3">
                Cliente gastou{" "}
                {currencyBRFormat(
                  customerHistory
                    .map((s) => parseFloat(s.total))
                    .reduce((a, b) => a + b, 0)
                )}
              </div>

              <PaginationBootstrap
                pageInfo={pageInfo}
                goToPage={customerHistoryByPagePage}
              />
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default HistoricoCliente;
