import React, { useState } from "react";
import { useRef } from "react";
import { createPart } from "../../../services/PartService";
import { toast } from "react-toastify";
import currencyBRFormat from "../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export const AdicionarPeca = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [finalValue, setFinalValue] = useState(0.0);
  const inputPrice = useRef("");
  const inputName = useRef("");
  const inputCost = useRef("");
  const [loading, setLoading] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      price: parseFloat(inputPrice.current.value),
      name: inputName.current.value,
      cost: parseFloat(inputCost.current.value),
    };

    createPart(data)
      .then((response) => {
        props.listParts();
        setIsOpen(false);
        setFinalValue(0);
        toast.success(response);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Não foi possivel adicionar serviço");
        console.log(error);
      });
  }

  function calculateFinalValue() {
    let cost = inputCost.current.value ?? 0;
    let price = inputPrice.current.value ?? 0;
    try {
      setFinalValue(parseFloat(price - cost));
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <div>
      <button onClick={() => setIsOpen(true)} type="button" className="btn">
        <i className="fa fa-plus me-2"></i>
        Nova peça
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Cadastro de nova peça</ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col col-12 col-sm-12 mb-3">
                <label htmlFor="serviceName" className="form-label bold">
                  Nome
                </label>
                <input
                  ref={inputName}
                  type="text"
                  id="serviceName"
                  className="form-control"
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Preço
                </label>
                <input
                  ref={inputPrice}
                  id="preco"
                  type="number"
                  step={0.01}
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="custo" className="form-label bold">
                  Custo
                </label>
                <input
                  ref={inputCost}
                  type="number"
                  id="custo"
                  step={0.01}
                  className="form-control"
                  onChange={() => calculateFinalValue()}
                  required
                />
              </div>

              <div className="col col-12 col-sm-4 mb-3">
                <label htmlFor="preco" className="form-label bold">
                  Lucro
                </label>

                <p>{currencyBRFormat(finalValue ?? 0.0)}</p>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark">
                  Adicionar
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default AdicionarPeca;
