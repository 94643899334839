import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { updateSale } from "../../../../services/SaleService";
import currencyBRFormat from "../../../../util/CurrencyUtils";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import MultiPayment from "../../../../components/Multipayment";
import paymentMethodBR from "../../../../util/paymentMethodUtils";
const dayjs = require("dayjs");

export const CorrigirVendaModal = (props) => {
  const [sale, setSale] = useState();
  const inputPaymentMethod = useRef("");
  const [isOpen, setIsOpen] = useState(false);
  const [discount, setDiscount] = useState(0.0);
  const [priceWithDiscount, setPriceWithDiscount] = useState(0.0);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0.0);
  const [loading, setLoading] = useState(false);
  const [discountTypePercentual, setDiscountTypePercentual] = useState(true);
  const [adtionalValue, setadtionalValue] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [multiPayment, setMultiPayment] = useState(false);

  const opened = { key: "OPENED", label: "Abertas", value: "Abertas" };

  useEffect(() => {
    setIsOpen(props.open);
    setSale(props.sale);
    setTotalWithDiscount(
      parseFloat(props.sale.total) +
        props.sale.adtionalValue -
        props.sale.discount
    );
    handleMultiPayment(props.sale);
  }, [props]);

  useEffect(() => {
    setSale(props.sale);
  }, [multiPayment]);

  function handleSubmit(e) {
    e.preventDefault();

    try {
      validatePaidValue();
      const execute = window.confirm(
        "Tem certeza que deseja corrigir esta venda?"
      );

      if (execute) {
        setLoading(true);
        const data = {
          paymentList: treatMultiPayment(),
        };

        updateSale(props.sale.id, data)
          .then((response) => {
            toast.success(response);
            props.setStatus(opened);
            toggle();
          })
          .catch((error) => {
            if (error.response.status == 422) toast.error(error.response.data);
            else toast.error("Não foi possível corrigir esta venda");
            setLoading(false);
          });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (sale) {
      setPriceWithDiscount(
        discountTypePercentual ? calculatePercentDiscount() : discount
      );
      calculateTotal();
    }
  }, [discountTypePercentual, discount, adtionalValue]);

  function calculatePercentDiscount() {
    return (
      (parseFloat(discount) / 100) *
      (parseFloat(sale.total) + parseFloat(adtionalValue))
    ).toFixed(2);
  }

  function calculateTotal() {
    setTotalWithDiscount(
      parseFloat(sale.total) +
        parseFloat(adtionalValue) -
        (discountTypePercentual ? calculatePercentDiscount() : discount)
    );
  }

  function toggle() {
    props.setOpen(false);
  }

  function treatMultiPayment() {
    if (inputPaymentMethod.current) {
      return [
        { key: inputPaymentMethod.current.value, value: totalWithDiscount },
      ];
    }

    return paymentList;
  }

  function validatePaidValue() {
    const paidValue = treatMultiPayment()
      .map((p) => p.value)
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    if (paidValue < totalWithDiscount) {
      throw new Error("Valor das formas de pagamento insufiente");
    }
  }

  function addPayment(paymentMethod, paymentValue) {
    const payment = paymentList.find(
      (p) => p.key == paymentMethod.current.value
    );

    if (paymentValue && paymentValue > 0) {
      const paidValue = paymentList
        .map((p) => p.value)
        .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

      if (paidValue + parseFloat(paymentValue) > totalWithDiscount) {
        toast.warning(
          `Não pode adicionar pagamento, resta pagar ${currencyBRFormat(
            totalWithDiscount - paidValue
          )}`
        );
        return;
      }
      if (payment) {
        toast.warning(
          `Forma de pagamento ${paymentMethodBR(
            paymentMethod.current.value
          )} já adicionada, remova ou adicione uma diferente`
        );
        return;
      }
      const item = {
        key: paymentMethod.current.value,
        name: paymentMethodBR(paymentMethod.current.value),
        value: paymentValue,
      };
      setPaymentList((prev) => [...prev, item]);
    } else {
      toast.warning("Valor da forma de pagamento deve ser maior que zero");
    }
  }

  function handleMultiPayment(sale) {
    if (sale && sale.paymentList.length > 1) {
      setMultiPayment(true);
      setPaymentList(sale.paymentList);
    }
  }

  if (!sale) return null;
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          Corrigir Venda {dayjs(sale.createdAt).format("DD/MM/YYYY HH:mm")}
        </ModalHeader>

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-lg-6 mb-lg-0 mb-3">
                <div className="mb-2">
                  <label className="form-label bold">Formas de pagamento</label>

                  <div className="align-self-center">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input "
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked={multiPayment}
                        onChange={() => setMultiPayment(!multiPayment)}
                      />
                      <label
                        className="form-check-label"
                        for="flexSwitchCheckDefault"
                      >
                        {multiPayment ? "Várias" : "Uma"}
                      </label>
                    </div>
                  </div>
                </div>

                {multiPayment ? (
                  <MultiPayment
                    inputPaymentMethod={inputPaymentMethod}
                    paymentList={paymentList}
                    addPayment={addPayment}
                    setPaymentList={setPaymentList}
                    totalWithDiscount={totalWithDiscount}
                  />
                ) : (
                  <select
                    id="paymentMethod"
                    ref={inputPaymentMethod}
                    className="form-select"
                    aria-label="Default select example"
                    defaultValue={
                      sale.paymentList[0] ? sale.paymentList[0].key : null
                    }
                    required
                  >
                    <option value="MONEY">Dinheiro</option>
                    <option value="CREDIT">Crédito à vista</option>
                    <option value="INSTALLMENT_CREDIT">
                      Crédito parcelado
                    </option>
                    <option value="DEBIT">Débito</option>
                    <option value="PIX">Pix</option>
                  </select>
                )}
              </div>

              <div className="col-lg-6">
                <label htmlFor="customerSelect" className="form-label bold">
                  Cliente
                </label>
                <Select
                  isDisabled={true}
                  id="customerSelect"
                  name="customers"
                  classNamePrefix="select"
                  className="basic-multi-select"
                  defaultValue={{
                    label: sale.customer
                      ? sale.customer.name
                      : "Não identificado",
                  }}
                  placeholder=""
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col col-lg-12">
                <table className="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">Qtd</th>
                      <th scope="col">Descrição</th>
                      <th scope="col">Preço</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.sale.servicesAndParts.map((obj) => (
                      <tr key={obj.name}>
                        <td>{obj.qtd}</td>
                        <td>{obj.name}</td>
                        <td>{currencyBRFormat(obj.price)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="row mt-3">
                <div className="col-lg-2 mt-lg-0 mt-2">
                  <label className="form-label bold">Subtotal</label>
                  <p className="mt-lg-1"> {currencyBRFormat(sale.total)}</p>
                </div>
                <div className="col-lg-3">
                  <label className="form-label bold">Valor Adicional</label>
                  <p className="mt-lg-1">
                    {currencyBRFormat(sale.adtionalValue)}
                  </p>
                </div>
                <div className="col-lg-2">
                  <label className="form-label bold">Desconto</label>
                  <p className="mt-lg-1">{currencyBRFormat(sale.discount)}</p>
                </div>
                <div className="col-lg-2">
                  <label className="form-label bold">Total</label>
                  <p className="mt-lg-1">
                    {currencyBRFormat(totalWithDiscount)}
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            {!loading ? (
              <>
                <button type="submit" className="btn btn-dark mx-3">
                  Corrigir
                </button>
                <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-danger"
                >
                  Cancelar
                </button>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default CorrigirVendaModal;
